/* BEGIN Light */
@import url(../fonts/material-design-icons/material-icons.css);
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Light/OpenSans-Light.eot?v=1.1.0");
  src: url("../fonts/Light/OpenSans-Light.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/Light/OpenSans-Light.woff?v=1.1.0") format("woff"), url("../fonts/Light/OpenSans-Light.ttf?v=1.1.0") format("truetype"), url("../fonts/Light/OpenSans-Light.svg?v=1.1.0#Light") format("svg");
  font-weight: 300;
  font-style: normal; }

/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/LightItalic/OpenSans-LightItalic.eot?v=1.1.0");
  src: url("../fonts/LightItalic/OpenSans-LightItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/LightItalic/OpenSans-LightItalic.woff?v=1.1.0") format("woff"), url("../fonts/LightItalic/OpenSans-LightItalic.ttf?v=1.1.0") format("truetype"), url("../fonts/LightItalic/OpenSans-LightItalic.svg?v=1.1.0#LightItalic") format("svg");
  font-weight: 300;
  font-style: italic; }

/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Regular/OpenSans-Regular.eot?v=1.1.0");
  src: url("../fonts/Regular/OpenSans-Regular.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/Regular/OpenSans-Regular.woff?v=1.1.0") format("woff"), url("../fonts/Regular/OpenSans-Regular.ttf?v=1.1.0") format("truetype"), url("../fonts/Regular/OpenSans-Regular.svg?v=1.1.0#Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Italic/OpenSans-Italic.eot?v=1.1.0");
  src: url("../fonts/Italic/OpenSans-Italic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/Italic/OpenSans-Italic.woff?v=1.1.0") format("woff"), url("../fonts/Italic/OpenSans-Italic.ttf?v=1.1.0") format("truetype"), url("../fonts/Italic/OpenSans-Italic.svg?v=1.1.0#Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

/* END Italic */
/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Semibold/OpenSans-Semibold.eot?v=1.1.0");
  src: url("../fonts/Semibold/OpenSans-Semibold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/Semibold/OpenSans-Semibold.woff?v=1.1.0") format("woff"), url("../fonts/Semibold/OpenSans-Semibold.ttf?v=1.1.0") format("truetype"), url("../fonts/Semibold/OpenSans-Semibold.svg?v=1.1.0#Semibold") format("svg");
  font-weight: 600;
  font-style: normal; }

/* END Semibold */
/* BEGIN Semibold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/SemiboldItalic/OpenSans-SemiboldItalic.eot?v=1.1.0");
  src: url("../fonts/SemiboldItalic/OpenSans-SemiboldItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/SemiboldItalic/OpenSans-SemiboldItalic.woff?v=1.1.0") format("woff"), url("../fonts/SemiboldItalic/OpenSans-SemiboldItalic.ttf?v=1.1.0") format("truetype"), url("../fonts/SemiboldItalic/OpenSans-SemiboldItalic.svg?v=1.1.0#SemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic; }

/* END Semibold Italic */
/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Bold/OpenSans-Bold.eot?v=1.1.0");
  src: url("../fonts/Bold/OpenSans-Bold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/Bold/OpenSans-Bold.woff?v=1.1.0") format("woff"), url("../fonts/Bold/OpenSans-Bold.ttf?v=1.1.0") format("truetype"), url("../fonts/Bold/OpenSans-Bold.svg?v=1.1.0#Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/BoldItalic/OpenSans-BoldItalic.eot?v=1.1.0");
  src: url("../fonts/BoldItalic/OpenSans-BoldItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/BoldItalic/OpenSans-BoldItalic.woff?v=1.1.0") format("woff"), url("../fonts/BoldItalic/OpenSans-BoldItalic.ttf?v=1.1.0") format("truetype"), url("../fonts/BoldItalic/OpenSans-BoldItalic.svg?v=1.1.0#BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic; }

/* END Bold Italic */
/* BEGIN Extrabold */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/ExtraBold/OpenSans-ExtraBold.eot?v=1.1.0");
  src: url("../fonts/ExtraBold/OpenSans-ExtraBold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/ExtraBold/OpenSans-ExtraBold.woff?v=1.1.0") format("woff"), url("../fonts/ExtraBold/OpenSans-ExtraBold.ttf?v=1.1.0") format("truetype"), url("../fonts/ExtraBold/OpenSans-ExtraBold.svg?v=1.1.0#ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal; }

/* END Extrabold */
/* BEGIN Extrabold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?v=1.1.0");
  src: url("../fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("../fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff?v=1.1.0") format("woff"), url("../fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.ttf?v=1.1.0") format("truetype"), url("../fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.svg?v=1.1.0#ExtraBoldItalic") format("svg");
  font-weight: 800;
  font-style: italic; }

/* END Extrabold Italic */
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", Arial, Helvetica;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Open Sans", Arial, Helvetica;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.2;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.718rem 0.562rem;
  padding-bottom: 0.718rem 0.562rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.53125rem; }

h4, .h4 {
  font-size: 1.3125rem; }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.125rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.718rem 0.562rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.8rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.2; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.75rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.2;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.625rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.640625rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.2;
  color: #fff;
  background-color: #28a745;
  border-radius: 0.125rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.2;
  color: #fff;
  background-color: #dc3545;
  border-radius: 0.125rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 0.125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: normal;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.125rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.2;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.125rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.640625rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.625rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.05rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.025rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.025rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.125rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.8rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.2;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.125rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.625rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.640625rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.8rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.8rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #80bdff; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.8rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.125rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 1.8rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.2;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.125rem 0.125rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.125rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.36875rem;
  padding-bottom: 0.36875rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.125rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.125rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.125rem - 1px) calc(0.125rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.125rem - 1px) calc(0.125rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.125rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.125rem - 1px);
  border-top-right-radius: calc(0.125rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.125rem - 1px);
  border-bottom-left-radius: calc(0.125rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.125rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.125rem;
            border-top-right-radius: 0.125rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.125rem;
            border-bottom-left-radius: 0.125rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.125rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.125rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.125rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 600; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.125rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: #000;
      text-decoration: none;
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.2; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.875rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.875rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.875rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", Arial, Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.625rem;
    height: 0.875rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.875rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.875rem 0.3125rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.875rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.875rem;
    height: 0.625rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.3125rem 0.875rem 0.3125rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.875rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.3125rem 0.875rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.875rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.875rem;
    height: 0.625rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.3125rem 0 0.3125rem 0.875rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 15.625rem;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.125rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", Arial, Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transition: none; } }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.125rem !important; }

.rounded-top {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important; }

.rounded-right {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important; }

.rounded-left {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

html {
  -webkit-tap-highlight-color: transparent;
  font-size: 12px; }

body {
  background-color: #fff;
  color: #212121;
  font-family: "Open Sans", Arial, Helvetica; }

a {
  color: #69c;
  text-decoration: none; }
  a:hover, a:focus {
    color: #69c;
    text-decoration: underline; }
  a.see-more-link i {
    font-size: 1.25rem;
    position: relative;
    top: 0.3125rem; }

.split-button {
  color: #69c; }
  .split-button .btn {
    padding: 0.125rem 0.25rem; }
  .split-button .dropdown {
    display: inline;
    padding-left: 0.312rem; }

.dropdown-menu {
  -moz-box-shadow: 0 0 0 0 #fff;
  -webkit-box-shadow: 0 0 0 0 #fff;
  box-shadow: 0 0 0 0 #fff;
  border: solid 1px #9e9e9e;
  border-radius: 0.125rem;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
  left: auto;
  margin-top: 0.3125rem;
  padding: 0;
  right: 0; }
  .dropdown-menu > li > a {
    padding: 0.3125rem 1.25rem;
    color: #333333;
    text-decoration: none; }
    .dropdown-menu > li > a:first-child {
      padding-top: 0.5rem; }
    .dropdown-menu > li > a:last-child {
      padding-bottom: 0.5rem; }

.btn-group > .btn-primary > .material-icons {
  font-size: 0.90625rem; }

.dropdown-item {
  text-decoration: none;
  padding: 0.3125rem 1.25rem;
  line-height: 1.2; }
  .dropdown-item:active {
    background-color: #fff;
    color: #212121; }
  .dropdown-item:focus, .dropdown-item:hover {
    background-color: #F4F5F5;
    color: #262626; }
  .dropdown-item:first-child {
    padding-top: 0.5rem; }
  .dropdown-item:last-child {
    padding-bottom: 0.5rem; }

.blockquote {
  padding: 0.5rem 1rem;
  margin: 0 0 1rem;
  font-size: 1.09375rem;
  border-left: 0.3125rem solid #eeeeee; }

.blockquote-reverse {
  padding-right: 0.9375rem;
  padding-left: 0;
  border-right: 0.3125rem solid #eeeeee;
  border-left: 0;
  text-align: right; }

.dropdown-toggle::after {
  display: none; }

.material-icons {
  font-size: 1.5rem;
  vertical-align: middle; }

.panel-heading .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit; }

.uppercase {
  text-transform: uppercase; }

.text-blue {
  color: #69c; }

a.see-more-link i {
  top: 0; }

.text-bold {
  font-weight: 600 !important; }

.text-center {
  text-align: center !important; }

.pl0 {
  padding-left: 0px; }

.pl10 {
  padding-left: 10px; }

.pl20 {
  padding-left: 20px; }

.pl30 {
  padding-left: 30px; }

.pr0 {
  padding-right: 0px; }

.pr10 {
  padding-right: 10px; }

.pr20 {
  padding-right: 20px; }

.pr30 {
  padding-right: 30px; }

.pt0 {
  padding-top: 0px; }

.pt10 {
  padding-top: 10px; }

.pt20 {
  padding-top: 20px; }

.pt30 {
  padding-top: 30px; }

.pb0 {
  padding-bottom: 0px; }

.pb10 {
  padding-bottom: 10px; }

.pb20 {
  padding-bottom: 20px; }

.pb30 {
  padding-bottom: 30px; }

.ml10 {
  margin-left: 10px; }

.ml20 {
  margin-left: 20px; }

.ml30 {
  margin-left: 30px; }

.ml40 {
  margin-left: 40px; }

.ml50 {
  margin-left: 50px; }

.ml60 {
  margin-left: 60px; }

.mr10 {
  margin-right: 10px; }

.mr20 {
  margin-right: 20px; }

.mr30 {
  margin-right: 30px; }

.mt10 {
  margin-top: 10px; }

.mt20 {
  margin-top: 20px; }

.mt30 {
  margin-top: 30px; }

.mb10 {
  margin-bottom: 10px; }

.mb20 {
  margin-bottom: 20px; }

.mb30 {
  margin-bottom: 30px; }

.m0 {
  margin: 0; }

.width25 {
  width: 25%; }

.width50 {
  width: 50%; }

.width75 {
  width: 75%; }

.width100 {
  width: 100%; }

.color-600 {
  color: #757575; }

.tcell-icon {
  color: #757575;
  font-size: 20px; }

.info-icon-tooltip {
  color: #757575;
  font-size: 20px;
  vertical-align: middle; }

::-webkit-scrollbar {
  height: 3px;
  width: 5px; }

::-webkit-scrollbar-button {
  height: 0;
  width: 0; }

::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border: 0;
  border-radius: 50px; }
  ::-webkit-scrollbar-thumb:hover {
    background: #212121; }
  ::-webkit-scrollbar-thumb:active {
    background: #69c; }

::-webkit-scrollbar-track {
  background: #f2f2f2;
  border: 0;
  border-radius: 50px; }
  ::-webkit-scrollbar-track:hover {
    background: #e0e0e0; }
  ::-webkit-scrollbar-track :active {
    background: #e0e0e0; }

::-webkit-scrollbar-corner {
  background: transparent; }

.font-color-grey-900 {
  color: #212121; }

.font-color-secondary {
  color: #69c; }

.fdl-2 .btn-primary-orange {
  border-color: rgba(0, 0, 0, 0.26);
  font-family: Segoe UI;
  font-weight: bold;
  color: #fff;
  background-color: #f60; }
  .fdl-2 .btn-primary-orange:not(:disabled):not(.disabled):hover {
    background-color: rgba(0, 0, 0, 0.38); }
  .fdl-2 .btn-primary-orange:not(:disabled):not(.disabled):focus {
    border-color: rgba(0, 0, 0, 0.38);
    outline: 0;
    box-shadow: 0 0 0.125rem 0.125rem rgba(0, 0, 0, 0.32); }
  .fdl-2 .btn-primary-orange:not(:disabled):not(.disabled):active {
    background-color: rgba(0, 0, 0, 0.62);
    border-color: rgba(0, 0, 0, 0.87);
    box-shadow: none; }
  .fdl-2 .btn-primary-orange:disabled {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.02);
    border-color: rgba(0, 0, 0, 0.12); }

.fdl-2 .btn-primary-blue {
  border-color: rgba(0, 0, 0, 0.26);
  font-family: Segoe UI;
  font-weight: bold;
  color: #fff;
  background-color: #2e8bc9; }
  .fdl-2 .btn-primary-blue:not(:disabled):not(.disabled):hover {
    background-color: rgba(0, 0, 0, 0.38); }
  .fdl-2 .btn-primary-blue:not(:disabled):not(.disabled):focus {
    border-color: rgba(0, 0, 0, 0.38);
    outline: 0;
    box-shadow: 0 0 0.125rem 0.125rem rgba(0, 0, 0, 0.32); }
  .fdl-2 .btn-primary-blue:not(:disabled):not(.disabled):active {
    background-color: rgba(0, 0, 0, 0.62);
    border-color: rgba(0, 0, 0, 0.87);
    box-shadow: none; }
  .fdl-2 .btn-primary-blue:disabled {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.02);
    border-color: rgba(0, 0, 0, 0.12); }

.fdl-2 .btn-primary-dark {
  border-color: rgba(0, 0, 0, 0.26);
  font-family: Segoe UI;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.54); }
  .fdl-2 .btn-primary-dark:not(:disabled):not(.disabled):hover {
    background-color: rgba(0, 0, 0, 0.38); }
  .fdl-2 .btn-primary-dark:not(:disabled):not(.disabled):focus {
    border-color: rgba(0, 0, 0, 0.38);
    outline: 0;
    box-shadow: 0 0 0.125rem 0.125rem rgba(0, 0, 0, 0.32); }
  .fdl-2 .btn-primary-dark:not(:disabled):not(.disabled):active {
    background-color: rgba(0, 0, 0, 0.62);
    border-color: rgba(0, 0, 0, 0.87);
    box-shadow: none; }
  .fdl-2 .btn-primary-dark:disabled {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.02);
    border-color: rgba(0, 0, 0, 0.12); }
  .fdl-2 .btn-primary-dark:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0.125rem 0.125rem rgba(255, 102, 0, 0.32); }

.fdl-2 .btn-secondary-medium, .fdl-2 .btn-secondary-light {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: Segoe UI;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  border-color: rgba(0, 0, 0, 0.38); }
  .fdl-2 .btn-secondary-medium:hover, .fdl-2 .btn-secondary-light:hover {
    background-color: rgba(0, 0, 0, 0.12); }
  .fdl-2 .btn-secondary-medium:not(:disabled):not(.disabled):focus, .fdl-2 .btn-secondary-light:not(:disabled):not(.disabled):focus {
    outline: 0;
    border-color: #f60;
    box-shadow: 0 0 0.125rem 0.125rem rgba(255, 102, 0, 0.32); }
  .fdl-2 .btn-secondary-medium:not(:disabled):not(.disabled):active, .fdl-2 .btn-secondary-light:not(:disabled):not(.disabled):active {
    background-color: rgba(0, 0, 0, 0.26);
    box-shadow: none; }
  .fdl-2 .btn-secondary-medium:disabled, .fdl-2 .btn-secondary-light:disabled {
    opacity: 1;
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.02);
    border-color: rgba(0, 0, 0, 0.12); }

.fdl-2 .btn-secondary-light {
  background-color: #fff; }

.fdl-2 .btn-borderless {
  font-weight: 600;
  font-family: Segoe UI;
  outline: 0;
  border: 1px solid transparent;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54); }
  .fdl-2 .btn-borderless > i {
    color: rgba(0, 0, 0, 0.54); }
  .fdl-2 .btn-borderless:not(:disabled):not(.disabled):hover {
    background-color: rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.12); }
  .fdl-2 .btn-borderless:not(:disabled):not(.disabled):focus {
    background-color: #fff;
    border: 1px solid #f60;
    box-shadow: 0 0 0.125rem 0.125rem rgba(255, 102, 0, 0.32); }
  .fdl-2 .btn-borderless:not(:disabled):not(.disabled):active {
    background-color: rgba(0, 0, 0, 0.26);
    border: 1px solid rgba(0, 0, 0, 0.26); }
  .fdl-2 .btn-borderless:disabled {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.26); }
    .fdl-2 .btn-borderless:disabled > i {
      color: rgba(0, 0, 0, 0.26); }

.btn {
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 0.125rem;
  font-weight: normal;
  margin-bottom: 0;
  white-space: nowrap; }
  .btn .material-icons {
    font-size: 0.875rem;
    margin: 0;
    padding: 0;
    vertical-align: inherit; }
  .btn:focus {
    outline-offset: 0.125rem; }

.btn-primary {
  color: #fff;
  background-color: #69c;
  border-color: #69c; }
  .btn-primary:hover {
    color: #212529;
    background-color: #fff;
    border-color: #4080bf; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 153, 204, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #69c;
    border-color: #69c; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4080bf;
    border-color: #3d79b6; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(102, 153, 204, 0.5); }
  .btn-primary.disabled, .btn-primary[disabled] {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    color: #bcbcbc;
    opacity: 1; }
    .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus {
      background-color: #e0e0e0;
      border-color: #e0e0e0;
      color: #bcbcbc; }
  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #3d5c7a;
    border-color: #3d5c7a; }
    .btn-primary:not(:disabled):not(.disabled):active:focus {
      background-color: #3d5c7a;
      border-color: #3d5c7a; }
    .btn-primary:not(:disabled):not(.disabled):active:hover {
      background-color: #3d5c7a;
      border-color: #3d5c7a; }
  .btn-primary:focus {
    background-color: #69c;
    border-color: #69c;
    box-shadow: 0 0 0.125rem 0.125rem rgba(56, 102, 195, 0.47);
    color: #fff; }
  .btn-primary:hover {
    background-color: #69c;
    border-color: #69c;
    color: #fff; }
  .btn-primary.dropdown-toggle:after {
    display: none; }

.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #69c;
  color: #69c; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #69c;
    border-color: #4080bf; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 153, 204, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #69c; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e5e5;
    border-color: #3d79b6; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(102, 153, 204, 0.5); }
  .btn-secondary.disabled, .btn-secondary[disabled] {
    background-color: #fff;
    border-color: #a6a6a6;
    color: #a6a6a6;
    opacity: 1; }
    .btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled].focus {
      background-color: #fff;
      border-color: #a6a6a6;
      color: #a6a6a6; }
  .btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #fff;
    border-color: #69c;
    color: #69c; }
    .btn-secondary:not(:disabled):not(.disabled):active:hover {
      background-color: #fff;
      border-color: #69c; }
  .btn-secondary:focus {
    background-color: #fff;
    border-color: #69c;
    box-shadow: 0 0 0.125rem 0.125rem rgba(56, 102, 195, 0.47); }
  .btn-secondary:hover {
    background-color: #fff;
    border-color: #69c;
    color: #69c; }
  .btn-secondary.dropdown-toggle:after {
    display: none; }

.btn-navigation {
  background-color: transparent;
  border-color: transparent;
  color: #212121; }
  .btn-navigation .material-icons {
    color: #69c;
    padding-right: 0.3125rem; }
  .btn-navigation:active {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    box-shadow: none;
    outline: none; }
    .btn-navigation:active:hover {
      background-color: #f2f2f2;
      border-color: #f2f2f2; }
    .btn-navigation:active:focus {
      box-shadow: none;
      outline: none; }
  .btn-navigation:hover {
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-navigation:focus {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    box-shadow: none;
    outline: none; }

.alt-style-nav {
  color: #997506; }

.btn-alt-style {
  background-color: #f8e71c;
  border-color: #f8e71c;
  color: #212121; }

.btn-alt-style-secondary {
  background-color: #fff;
  border-color: #f8e71c;
  color: #212121; }

.btn-icon {
  padding: 0.4375rem 0.5rem; }
  .btn-icon .material-icons {
    font-size: 1.125rem;
    margin: 0;
    padding: 0; }

.btn-icon-sm {
  padding: 0.3125rem 0.5rem; }
  .btn-icon-sm .material-icons {
    font-size: 1.125rem;
    margin: 0;
    padding: 0; }

.btn-icon-xs {
  padding: 0 0.0625rem; }
  .btn-icon-xs .material-icons {
    font-size: 1rem;
    margin: 0;
    padding: 0; }

.icon-before {
  padding-left: 0.625rem;
  padding-right: 0.9375rem; }

.icon-after {
  padding-left: 0.9375rem;
  padding-right: 0.625rem; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.937rem;
  font-size: 1rem;
  line-height: 1.4;
  border-radius: 0.125rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  line-height: 0.9;
  border-radius: 0.125rem; }

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }
  .btn-default:hover, .btn-default:active {
    background-color: #e6e5e5;
    border-color: #adadad; }
  .btn-default:focus {
    background-color: #e6e5e5;
    border-color: #8c8c8c; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.btn .caret {
  margin-left: 0; }

.btn-group > .btn-primary,
.btn-group .btn-secondary,
.btn-group .btn-alt-style {
  border-color: #9e9e9e; }
  .btn-group > .btn-primary.disabled, .btn-group > .btn-primary[disabled],
  .btn-group .btn-secondary.disabled,
  .btn-group .btn-secondary[disabled],
  .btn-group .btn-alt-style.disabled,
  .btn-group .btn-alt-style[disabled] {
    border-color: #bdbdbd;
    color: #bcbcbc; }

.btn-group .btn-primary + .dropdown-toggle:after {
  display: none; }

.btn-group:focus {
  box-shadow: 0 0 2px 2px rgba(56, 102, 195, 0.47); }
  .btn-group:focus .btn-primary + .dropdown-toggle {
    background-color: #3d5c7a;
    border: 1px solid #3d5c7a; }
  .btn-group:focus .btn-primary:not(.dropdown-toggle) {
    border: 1px solid #3d5c7a; }

.btn-group:active {
  box-shadow: none; }
  .btn-group:active .btn-primary + .dropdown-toggle {
    background-color: #69c;
    border: 1px solid #69c; }
  .btn-group:active .btn-primary:not(.dropdown-toggle) {
    background-color: #3d5c7a;
    border: 1px solid #3d5c7a; }

.btn-split .btn-primary:not(.dropdown-toggle) {
  border: 1px solid #69c;
  border-right: 1px solid #3d5c7a; }
  .btn-split .btn-primary:not(.dropdown-toggle):focus {
    box-shadow: none; }
  .btn-split .btn-primary:not(.dropdown-toggle).disabled,
  .btn-split .btn-primary:not(.dropdown-toggle) [disabled] {
    background-color: #dedede;
    border: 1px solid #e0e0e0;
    border-right: 1px solid #bdbdbd;
    color: #bcbcbc; }

.btn-split .btn-primary + .dropdown-toggle {
  border: 1px solid #69c;
  border-left: 1px solid #3d5c7a; }
  .btn-split .btn-primary + .dropdown-toggle:focus {
    box-shadow: none; }
  .btn-split .btn-primary + .dropdown-toggle:active {
    background-color: #3d5c7a;
    border: 1px solid #3d5c7a; }
  .btn-split .btn-primary + .dropdown-toggle.disabled,
  .btn-split .btn-primary + .dropdown-toggle [disabled] {
    background-color: #dedede;
    border: 1px solid #e0e0e0;
    border-left-color: transparent;
    color: #bcbcbc; }

.btn-split > .btn-secondary {
  border: 1px solid #69c; }
  .btn-split > .btn-secondary:focus {
    box-shadow: none; }
  .btn-split > .btn-secondary.disabled, .btn-split > .btn-secondary[disabled] {
    border: 1px solid #bcbcbc;
    color: #bcbcbc; }
  .btn-split > .btn-secondary > .btn-secondary + .dropdown-toggle.disabled, .btn-split > .btn-secondary > .btn-secondary + .dropdown-toggle[disabled] {
    border: 1px solid #bcbcbc;
    border-left-width: 0; }
  .btn-split > .btn-secondary .btn-secondary:not(.dropdown-toggle).disabled, .btn-split > .btn-secondary .btn-secondary:not(.dropdown-toggle)[disabled] {
    border: 1px solid #bcbcbc;
    border-right: 1px solid #e0e0e0; }

.btn-split > .btn-alt-style:not(.dropdown-toggle) {
  border: 1px solid #f8e71c;
  border-right: 1px solid #dacb18; }

.btn-split > .btn-alt-style + .dropdown-toggle {
  border: 1px solid #f8e71c;
  border-left: 1px solid #dacb18; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.btn-toolbar {
  flex-wrap: nowrap; }
  .btn-toolbar > .btn,
  .btn-toolbar > .btn-group,
  .btn-toolbar > .input-group {
    margin-left: 5px; }

.fdl-2.btn-group > .btn-primary,
.fdl-2.btn-group .btn-secondary,
.fdl-2.btn-group .btn-alt-style {
  border-color: #9e9e9e; }
  .fdl-2.btn-group > .btn-primary.disabled, .fdl-2.btn-group > .btn-primary[disabled],
  .fdl-2.btn-group .btn-secondary.disabled,
  .fdl-2.btn-group .btn-secondary[disabled],
  .fdl-2.btn-group .btn-alt-style.disabled,
  .fdl-2.btn-group .btn-alt-style[disabled] {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.02);
    border-color: rgba(0, 0, 0, 0.12); }
  .fdl-2.btn-group > .btn-primary.disabled:focus,
  .fdl-2.btn-group .btn-secondary.disabled:focus,
  .fdl-2.btn-group .btn-alt-style.disabled:focus {
    box-shadow: unset;
    outline: unset; }

.fdl-2.btn-group .btn-primary + .dropdown-toggle:after {
  display: none; }

.fdl-2.btn-group a.btn.fdl-2-toggle, .fdl-2.btn-group a.btn.icon-toggle {
  color: #000; }
  .fdl-2.btn-group a.btn.fdl-2-toggle:hover, .fdl-2.btn-group a.btn.icon-toggle:hover {
    background-color: rgba(0, 0, 0, 0.38); }
  .fdl-2.btn-group a.btn.fdl-2-toggle:focus, .fdl-2.btn-group a.btn.icon-toggle:focus {
    border-color: #f60;
    box-shadow: 0 0 0.125rem 0.125rem rgba(255, 102, 0, 0.32); }

.fdl-2.btn-group a.btn.fdl-2-toggle.active {
  border-color: rgba(0, 0, 0, 0.26);
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.54); }

.fdl-2.btn-group:focus {
  box-shadow: 0 0 2px 2px rgba(56, 102, 195, 0.47); }
  .fdl-2.btn-group:focus .btn-primary + .dropdown-toggle {
    background-color: #3d5c7a;
    border: 1px solid #3d5c7a; }
  .fdl-2.btn-group:focus .btn-primary:not(.dropdown-toggle) {
    border: 1px solid #3d5c7a; }

.fdl-2.btn-group:active {
  box-shadow: none; }
  .fdl-2.btn-group:active .btn-primary + .dropdown-toggle {
    background-color: #69c;
    border: 1px solid #69c; }
  .fdl-2.btn-group:active .btn-primary:not(.dropdown-toggle) {
    background-color: #3d5c7a;
    border: 1px solid #3d5c7a; }

html {
  font-size: 16px; }

h1,
h2,
h3,
h4 {
  color: #212121; }

h1 {
  font-size: 3rem;
  font-style: normal;
  font-weight: normal;
  line-height: 3.75rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem; }

h4, h5, h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 0.95rem; }

h6 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.825rem; }

.txt-subheading {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; }

p,
.txt-std {
  font-size: 0.90625rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  margin: 0 0 0.5rem; }

small,
.text-sm {
  color: #757575;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem; }

.bold600, .table > tbody > tr.tr-error-msg > td {
  font-weight: 600; }

.lead {
  font-size: 1.3125rem;
  margin-bottom: 1rem;
  line-height: 2rem; }

p,
.lead {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; }

.fdl-2 .alert-info, .fdl-2 .alert-success, .fdl-2 .alert-warning, .fdl-2 .alert-danger {
  border-color: rgba(0, 0, 0, 0.12); }

.fdl-2 .alert-info {
  border-top: 4px solid #2e8bc9; }

.fdl-2 .alert-success {
  border-top: 4px solid #99cc00; }

.fdl-2 .alert-warning {
  border-top: 4px solid #ffc626; }

.fdl-2 .alert-danger {
  border-top: 4px solid #c00000; }

.alert {
  border-radius: 2;
  margin-bottom: 1rem;
  padding: 0.75rem 2.1875rem 0.75rem 2.8125rem;
  position: relative; }
  .alert h4 {
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 0; }
  .alert > i {
    display: table;
    left: 0.937rem;
    position: absolute;
    right: 0.937rem; }
  .alert .material-icons {
    font-size: 1.5rem; }

.alert-dismissable,
.alert-dismissible {
  padding-right: 2.187rem; }
  .alert-dismissable .close,
  .alert-dismissible .close {
    color: #757575;
    position: relative;
    right: 1.562rem;
    top: 0;
    left: 1.25rem;
    padding: 0.75rem 0; }

.grouped-messages .alert {
  border-color: #9e9e9e;
  border-radius: 0;
  margin-bottom: 0;
  margin-top: -0.062rem; }
  .grouped-messages .alert:first-child {
    margin-top: 0; }

.alert-success {
  background-color: #fff;
  border-color: #54b34a;
  color: inherit; }
  .alert-success i {
    color: #54b34a;
    top: 0.7rem; }

.alert-info {
  background-color: #fff;
  border-color: #3966c3;
  color: inherit; }
  .alert-info i {
    color: #3966c3;
    top: 0.7rem; }

.alert-warning {
  background-color: #fff;
  border-color: #f3b823;
  color: inherit; }
  .alert-warning i {
    color: #f3b823;
    top: 0.7rem; }

.alert-danger {
  background-color: #fff;
  border-color: #dd3435;
  color: #dd3435; }
  .alert-danger h4,
  .alert-danger span {
    color: #dd3435; }
  .alert-danger > i {
    color: #dd3435;
    top: 0.7rem; }
  .alert-danger a {
    color: #69c; }
  .alert-danger p,
  .alert-danger li {
    color: #212121; }
  .alert-danger ul {
    padding: 0.625rem 0 0.625rem 1.25rem; }
    .alert-danger ul li {
      padding-bottom: 0.312rem; }
  .alert-danger a.see-more-link i {
    top: 0; }

.critical-alert {
  background-color: #dd3435;
  border-color: #dd3435;
  color: #fff; }
  .critical-alert h4,
  .critical-alert span {
    color: #fff; }
  .critical-alert > i {
    color: #fff;
    top: 0.7rem; }
  .critical-alert a {
    color: #fff; }
  .critical-alert p,
  .critical-alert li {
    color: #fff; }
  .critical-alert ul {
    padding: 0.625rem 0 0.625rem 1.25rem; }
    .critical-alert ul li {
      padding-bottom: 0.312rem; }
  .critical-alert a.see-more-link i {
    top: 0; }
  .critical-alert a {
    text-decoration: underline; }
  .critical-alert .close {
    color: #fff; }

.alert-message-success p {
  color: #212121;
  padding-left: 1.25rem;
  padding-top: 0.312rem;
  position: relative; }
  .alert-message-success p i {
    color: #54b34a;
    font-size: 1rem;
    left: 0;
    position: absolute;
    top: 0.562rem; }

.alert-message-success input, .alert-message-success select {
  border-color: #54b34a; }

.alert-message-success .checkbox label::before,
.alert-message-success .radio label::before {
  border-color: #54b34a !important; }

.alert-message-success .checkbox p,
.alert-message-success .radio p {
  padding-left: 0; }

.alert-message-error p {
  color: #dd3435;
  padding-left: 1.25rem;
  padding-top: 0.312rem;
  position: relative; }
  .alert-message-error p i {
    color: #dd3435;
    font-size: 1rem;
    left: 0;
    position: absolute;
    top: 0.562rem; }

.alert-message-error input, .alert-message-error select {
  border-color: #dd3435; }

.alert-message-error .checkbox label::before,
.alert-message-error .radio label::before {
  border-color: #dd3435 !important; }

.alert-message-error .checkbox p,
.alert-message-error .radio p {
  padding-left: 0; }

.alert-message-error label {
  position: relative; }
  .alert-message-error label span.required {
    color: #dd3435;
    left: -0.75rem;
    padding-left: 0;
    position: absolute;
    top: 0.062rem; }

table > tbody > tr.alert-message-before {
  background-color: #fafafa; }
  table > tbody > tr.alert-message-before td {
    border: solid 0.125rem #dd3435;
    color: #dd3435;
    padding: 0.312rem 0.562rem; }
    table > tbody > tr.alert-message-before td i {
      color: #dd3435;
      font-size: 0.90625rem;
      position: relative; }

table > tbody > tr.alert-message {
  background-color: #fafafa;
  border: solid 0.125rem #dd3435; }
  table > tbody > tr.alert-message td {
    border-bottom: solid 0.125rem #dd3435; }

.close {
  opacity: 1;
  filter: alpha(opacity=100);
  color: #757575;
  float: right;
  font-size: 30px;
  font-weight: normal;
  line-height: 1px;
  text-shadow: none; }
  .close:hover, .close:focus {
    opacity: .5;
    color: #757575;
    cursor: pointer;
    text-decoration: none; }

button.close {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 10px 0; }

.fdl-switch > input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none; }

.fdl-switch > label {
  content: 'ON';
  cursor: pointer;
  height: 0;
  position: relative;
  width: 66px; }
  .fdl-switch > label::before {
    background: #e0e0e0;
    border-radius: 14.5px;
    content: '';
    height: 28px;
    margin-top: -10px;
    position: absolute;
    transition: .4s ease-in-out;
    width: 66px; }
  .fdl-switch > label::after {
    background: #fff;
    border: 0.5px solid #757575;
    border-radius: 14.5px;
    content: '';
    height: 32px;
    left: 0;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: .3s ease-in-out;
    width: 32px; }

.fdl-switch .onoffswitch::before {
  color: #fff;
  content: 'ON';
  display: block;
  float: left;
  font-size: 0.75rem;
  left: 10px;
  position: absolute;
  top: -3px; }

.fdl-switch .onoffswitch::after {
  color: #212121;
  content: 'OFF';
  display: block;
  float: left;
  font-size: 0.75rem;
  left: 35px;
  position: absolute;
  top: -3px; }

.fdl-switch.fdl-2 > label {
  content: 'ON';
  cursor: pointer;
  height: 0;
  position: relative;
  width: 66px; }
  .fdl-switch.fdl-2 > label::before {
    background: #fff;
    border-radius: 14.5px;
    content: '';
    height: 28px;
    margin-top: -10px;
    position: absolute;
    transition: .4s ease-in-out;
    width: 66px;
    border: 1px solid rgba(0, 0, 0, 0.54); }
  .fdl-switch.fdl-2 > label::after {
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.54);
    border-radius: 14.5px;
    content: '';
    height: 22px;
    left: 4px;
    margin-top: 0px;
    position: absolute;
    top: -7px;
    transition: .3s ease-in-out;
    width: 22px; }
  .fdl-switch.fdl-2 > label:hover::before {
    background: rgba(0, 0, 0, 0.26); }

.fdl-switch.fdl-2 .onoffswitch::before {
  color: transparent;
  content: 'On';
  display: block;
  float: left;
  font-size: 14px;
  left: 10px;
  position: absolute;
  top: -3px; }

.fdl-switch.fdl-2 .onoffswitch::after {
  color: rgba(0, 0, 0, 0.87);
  content: 'Off';
  display: block;
  float: left;
  font-size: 14px;
  left: 35px;
  position: absolute;
  top: -3px; }

.fdl-switch > input[type='checkbox']:checked + label::before {
  background: #69c; }

.fdl-switch > input[type='checkbox']:checked + label::after {
  background: #fff;
  background: -webkit-linear-gradient(#fff, #f3f3f3);
  background: -o-linear-gradient(#fff, #f3f3f3);
  background: -moz-linear-gradient(#fff, #f3f3f3);
  background: linear-gradient(#fff, #f3f3f3);
  border: 0.5px solid #69c;
  left: 34px; }

.fdl-switch.fdl-2 > input[type='checkbox']:checked + label::before {
  background: rgba(0, 0, 0, 0.54); }

.fdl-switch.fdl-2 > input[type='checkbox']:checked + label::after {
  background: #fff;
  background: -webkit-linear-gradient(#fff, #f3f3f3);
  background: -o-linear-gradient(#fff, #f3f3f3);
  background: -moz-linear-gradient(#fff, #f3f3f3);
  background: linear-gradient(#fff, #f3f3f3);
  border: 0.5px solid rgba(0, 0, 0, 0.54);
  left: 40px; }

.fdl-switch.fdl-2 > input[type='checkbox']:checked + label:hover::before {
  background: rgba(0, 0, 0, 0.38); }

.fdl-switch.fdl-2 > input[type='checkbox']:checked + label .onoffswitch::before {
  color: #fff;
  content: 'On';
  display: block;
  float: left;
  font-size: 14px;
  left: 14px;
  position: absolute;
  top: -4px;
  font-weight: bold; }

.fdl-switch.fdl-2 > input[type='checkbox']:checked + label .onoffswitch::after {
  color: transparent;
  content: 'Off';
  display: block;
  float: left;
  font-size: 14px;
  left: 35px;
  position: absolute;
  top: -3px; }

.fdl-switch.fdl-2 > input[type='checkbox']:focus + label::before {
  border: 1px solid #f60;
  box-shadow: 0 0 0.125rem 0.125rem rgba(255, 102, 0, 0.32); }

.fdl-switch.fdl-2 > input[disabled] + label {
  cursor: not-allowed; }
  .fdl-switch.fdl-2 > input[disabled] + label::before {
    background: #fff;
    border-radius: 14.5px;
    content: '';
    height: 28px;
    margin-top: -10px;
    position: absolute;
    transition: .4s ease-in-out;
    width: 66px;
    border: 1px solid rgba(0, 0, 0, 0.12); }
  .fdl-switch.fdl-2 > input[disabled] + label::after {
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    border-radius: 14.5px;
    content: '';
    height: 22px;
    left: 4px;
    margin-top: 0px;
    position: absolute;
    top: -7px;
    transition: .3s ease-in-out;
    width: 22px; }
  .fdl-switch.fdl-2 > input[disabled] + label .onoffswitch::after {
    color: rgba(0, 0, 0, 0.12);
    content: 'Off';
    display: block;
    float: left;
    font-size: 14px;
    left: 35px;
    position: absolute;
    top: -3px; }

.altstyleswitch > input[type="checkbox"]:checked + label::before {
  background: #f8e71c; }

.altstyleswitch .onoffswitch::before {
  color: #212121; }

.material-icons-rd-chbx, .checkbox input[type='checkbox'] + label::before, .radio input[type='radio'] + label::before, .checkbox.fdl-2 input[type='checkbox'] + label::before, .fdl-2.radio input[type='radio'] + label::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  direction: rtl;
  display: inline-block;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  height: 1em;
  letter-spacing: normal;
  line-height: 1;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  width: 1em;
  word-wrap: normal; }

.checkbox input[type='checkbox'] {
  display: block;
  opacity: 0;
  position: absolute; }

.checkbox input[type='checkbox'] + label::before {
  background-color: #fff;
  border: 0.0625rem solid #757575;
  border-radius: 0.125rem;
  color: #fff;
  content: '';
  font-size: 1rem;
  height: 1.125rem;
  width: 1.125rem;
  position: absolute;
  top: 0.25rem;
  left: 0; }

.checkbox input[type='checkbox'].error + label::before {
  border-color: #dd3435; }

.checkbox input[type='checkbox']:checked:focus + label::before {
  border-color: #69c;
  box-shadow: 0 0 0.125rem 0.125rem rgba(56, 102, 195, 0.47);
  content: '\E876'; }

.checkbox input[type='checkbox']:focus + label::before {
  border-color: #69c;
  box-shadow: 0 0 0.125rem 0.125rem rgba(56, 102, 195, 0.47); }

.checkbox input[type='checkbox']:disabled + label::before {
  border: 0.0625rem solid #e0e0e0; }

.checkbox input[type='checkbox']:disabled + label {
  color: #bcbcbc; }

.checkbox input[type='checkbox']:checked + label::before {
  color: #757575;
  content: '\E876'; }

.checkbox input[type='checkbox']:checked:hover + label::before {
  background-color: #69c;
  border-color: #69c;
  color: #fff; }

.radio input[type='radio'] {
  display: contents;
  opacity: 0; }

.radio input[type='radio'] + label {
  display: inline; }

.radio input[type='radio'] + label::before {
  border: 0.0625rem solid #757575;
  border-radius: 50%;
  color: #fff;
  content: '';
  direction: ltr;
  font-size: 0.75rem;
  height: 1.125rem;
  padding: 0.09375rem;
  width: 1.125rem;
  position: absolute;
  top: 0.375rem;
  left: 0; }

.radio input[type='radio']:not(:checked):focus + label::before {
  border-color: #69c;
  box-shadow: 0 0 0.125rem 0.125rem rgba(56, 102, 195, 0.47); }

.radio input[type='radio'].error + label::before {
  border-color: #dd3435; }

.radio input[type='radio']:checked + label::before {
  color: #757575;
  content: '\E3FA'; }

.radio input[type='radio']:checked:hover + label::before {
  background-color: #69c;
  border-color: #69c;
  color: #fff; }

.radio input[type='radio']:checked:focus + label::before {
  border-color: #69c;
  box-shadow: 0 0 0.125rem 0.125rem rgba(56, 102, 195, 0.47);
  content: '\E3FA'; }

.radio input[type='radio']:disabled + label::before {
  border: 0.0625rem solid #e0e0e0; }

.radio input[type='radio']:disabled + label {
  color: #bcbcbc; }

.radio label,
.checkbox label {
  padding-left: 0;
  position: relative;
  cursor: pointer;
  bottom: 0.25rem; }
  .radio label span,
  .checkbox label span {
    padding-left: 1.5625rem;
    vertical-align: top;
    word-wrap: break-word;
    display: inline-block;
    line-height: 1.667rem; }

.fdl-2.radio label,
.fdl-2.checkbox label {
  padding-left: 0;
  position: relative;
  cursor: pointer;
  bottom: 0.25rem; }
  .fdl-2.radio label span,
  .fdl-2.checkbox label span {
    padding-left: 1.5625rem;
    vertical-align: top;
    word-wrap: break-word;
    display: inline-block;
    line-height: 1.667rem; }

.checkbox.fdl-2 input[type='checkbox'] {
  display: block;
  opacity: 0;
  position: absolute; }

.checkbox.fdl-2 input[type='checkbox'] + label::before {
  background-color: #fff;
  border: 0.0425rem solid rgba(0, 0, 0, 0.26);
  border-radius: unset;
  color: unset;
  content: '';
  font-size: 1rem;
  height: 1.125rem;
  width: 1.125rem;
  position: absolute;
  top: 0.25rem;
  left: 0; }

.checkbox.fdl-2 input[type='checkbox'].error + label::before {
  border-color: #dd3435; }

.checkbox.fdl-2 input[type='checkbox']:checked:focus + label::before {
  border-color: #f60;
  box-shadow: 0 0 0.125rem 0.125rem rgba(255, 102, 0, 0.32);
  content: '\E876'; }

.checkbox.fdl-2 input[type='checkbox']:focus + label::before {
  border-color: #69c;
  box-shadow: 0 0 0.125rem 0.125rem rgba(56, 102, 195, 0.47); }

.checkbox.fdl-2 input[type='checkbox']:disabled + label::before {
  border: 0.0625rem solid #e0e0e0; }

.checkbox.fdl-2 input[type='checkbox']:disabled:hover + label::before {
  background-color: unset;
  cursor: default; }

.checkbox.fdl-2 input[type='checkbox']:disabled + label {
  color: #bcbcbc; }

.checkbox.fdl-2 input[type='checkbox']:checked + label::before {
  color: #757575;
  content: '\E876';
  font-weight: bold; }

.checkbox.fdl-2 input[type='checkbox']:checked:hover + label::before {
  background-color: #f60;
  border-color: #f60;
  color: #fff; }

.checkbox.fdl-2 input[type='checkbox']:hover + label::before {
  background-color: rgba(0, 0, 0, 0.12); }

.checkbox.fdl-2 input[type='checkbox']:focus + label::before {
  outline: 0;
  border-color: #f60;
  box-shadow: 0 0 0.125rem 0.125rem rgba(255, 102, 0, 0.32); }

.fdl-2.radio input[type='radio'] {
  display: contents;
  opacity: 0; }

.fdl-2.radio input[type='radio'] + label {
  display: inline; }

.fdl-2.radio input[type='radio'] + label::before {
  border: 0.0425rem solid rgba(0, 0, 0, 0.26);
  border-radius: 50%;
  color: #fff;
  content: '';
  direction: ltr;
  font-size: 0.75rem;
  height: 1.125rem;
  padding: 0.157rem;
  width: 1.125rem;
  position: absolute;
  top: 0.375rem;
  left: 0; }

.fdl-2.radio input[type='radio']:not(:checked):focus + label::before {
  border-color: #69c;
  box-shadow: 0 0 0.125rem 0.125rem rgba(56, 102, 195, 0.47); }

.fdl-2.radio input[type='radio'].error + label::before {
  border-color: #dd3435; }

.fdl-2.radio input[type='radio']:checked + label::before {
  color: #757575;
  content: '\E3FA'; }

.fdl-2.radio input[type='radio']:checked:hover + label::before {
  background-color: #f60;
  border-color: #f60;
  color: #fff; }

.fdl-2.radio input[type='radio']:hover + label::before {
  background-color: rgba(0, 0, 0, 0.12); }

.fdl-2.radio input[type='radio']:checked:focus + label::before {
  border-color: #f60;
  box-shadow: 0 0 0.125rem 0.125rem rgba(255, 102, 0, 0.32);
  content: '\E3FA'; }

.fdl-2.radio input[type='radio']:disabled + label::before {
  border: 0.0625rem solid #e0e0e0; }

.fdl-2.radio input[type='radio']:disabled + label {
  color: #bcbcbc; }

.fdl-2.radio input[type='radio']:disabled:hover + label::before {
  background-color: unset;
  cursor: default; }

.fdl-2 .form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid #9e9e9e;
  border-radius: 0.125rem;
  box-shadow: none;
  color: #212121;
  font-size: 0.90625rem;
  line-height: 1.2;
  padding: 0.5rem 0.625rem; }
  .fdl-2 .form-control[disabled], .fdl-2 .form-control[readonly] {
    border-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.38);
    background-color: rgba(0, 0, 0, 0.02); }
    .fdl-2 .form-control[disabled]:hover, .fdl-2 .form-control[readonly]:hover {
      background-color: rgba(0, 0, 0, 0.02); }
  .fdl-2 .form-control:hover {
    background-color: rgba(0, 0, 0, 0.12); }
  .fdl-2 .form-control:focus {
    border-color: rgba(255, 102, 0, 0.32);
    box-shadow: 0 0 0.125rem 0.125rem rgba(255, 102, 0, 0.32); }
  .fdl-2 .form-control.ng-invalid {
    background-color: #fff;
    border: 1px solid #dd3435; }

.fdl-2 .changed-input {
  border-left: 4px solid #f60; }

label {
  color: #757575;
  font-size: 0.90625rem;
  font-weight: normal;
  margin-bottom: .312rem; }
  label[disabled], label[readonly] {
    color: #9e9e9e; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  padding-bottom: 4px;
  font-size: 1.25rem;
  line-height: inherit;
  color: #212121;
  border: 0;
  border-bottom: 1px solid #e0e0e0; }

input.form-control {
  height: 2.125rem; }

.form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid #9e9e9e;
  border-radius: 0.125rem;
  box-shadow: none;
  color: #212121;
  font-size: 0.90625rem;
  line-height: 1.2;
  padding: 0.5rem 0.625rem; }
  .form-control[disabled], .form-control[readonly] {
    background-color: #f2f2f2;
    border-color: #e0e0e0; }
  .form-control:focus {
    border-color: #3966c3;
    box-shadow: outline 0 1px 1px rgba(56, 102, 195, 0.21); }
  .form-control.ng-invalid {
    background-color: #fff;
    border: 1px solid #dd3435; }

/* Fix to restrict form controls padding in Firefox. Below CSS extension is to target only the Firefox browser. */
@-moz-document url-prefix() {
  .form-control {
    padding-top: 6px;
    padding-bottom: 6px; } }

.has-error .form-control {
  border-color: #dd3435;
  box-shadow: outline 0 1px 1px rgba(220, 52, 53, 0.2); }

.has-error .control-label {
  color: #757575; }

.has-error span {
  color: #dd3435; }

.custom-search-input {
  margin: 0;
  padding: 0; }
  .custom-search-input input {
    padding-right: 30px; }
  .custom-search-input button {
    background: none;
    border: 0;
    color: #9e9e9e;
    left: -33px;
    margin-bottom: 0;
    margin-top: 2px;
    padding: 2px 5px;
    position: relative; }
    .custom-search-input button .material-icons {
      font-size: 1.25rem; }
  .custom-search-input .input-group > .form-control:first-child {
    border-bottom-right-radius: 0.125rem;
    border-top-right-radius: 0.125rem; }
  .custom-search-input .input-group-btn:last-child > .btn {
    z-index: 3; }

.input-group-btn {
  width: 0; }

select.form-control {
  padding-right: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 1.3; }
  select.form-control::-ms-expand {
    display: none; }

.fdl-select {
  display: block;
  position: relative; }
  .fdl-select::after {
    color: #757575;
    content: '\E5CF';
    font-family: 'Material Icons';
    font-feature-settings: 'liga';
    font-size: 1.125rem;
    height: 100%;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 11px;
    text-rendering: optimizeLegibility;
    top: 0.512rem;
    height: 30px;
    width: 20px; }

.form-horizontal label.inline-label-left {
  text-align: left; }

.form-horizontal .radio {
  padding-top: 0.5625rem; }

.form-horizontal .control-label {
  padding-top: 0.5625rem; }

.modal-buttons button {
  margin-bottom: 0.625rem; }

.modal-dialog .modal-header {
  border-bottom: 0 !important; }
  .modal-dialog .modal-header .modal-title {
    margin: 0; }

.modal-dialog .modal-content {
  border: solid 0.0625rem #9e9e9e;
  border-radius: 2;
  word-wrap: break-word; }
  .modal-dialog .modal-content .modal-header, .modal-dialog .modal-content .modal-footer {
    padding: 0.9375rem 1.875rem; }
  .modal-dialog .modal-content .modal-header {
    border-bottom: 0.125rem solid #e0e0e0 !important;
    padding: 0.9375rem;
    position: relative; }
    .modal-dialog .modal-content .modal-header .close {
      color: #424242;
      position: absolute;
      top: 0.6rem;
      right: 0.9375rem;
      margin-top: 0; }
    .modal-dialog .modal-content .modal-header .critical-alert {
      width: 100%; }
      .modal-dialog .modal-content .modal-header .critical-alert i {
        top: auto; }
      .modal-dialog .modal-content .modal-header .critical-alert .close {
        color: #fff;
        top: 0.7rem;
        right: 2rem;
        left: unset; }
    .modal-dialog .modal-content .modal-header h4 {
      font-weight: normal; }
      .modal-dialog .modal-content .modal-header h4.success {
        padding-left: 1.875rem; }
      .modal-dialog .modal-content .modal-header h4 .material-icons {
        color: #54b34a;
        left: 0.9375rem;
        position: absolute;
        top: 0.9375rem;
        font-size: 1.5rem; }
    .modal-dialog .modal-content .modal-header .alert {
      margin-bottom: 0; }
      .modal-dialog .modal-content .modal-header .alert .material-icons {
        font-size: 1.5rem; }
  .modal-dialog .modal-content .modal-footer {
    background: #fafafa;
    border-top: 0.125rem solid #e0e0e0; }
    .modal-dialog .modal-content .modal-footer .btn + .btn {
      margin-left: 1.5625rem; }

.modal.error .modal-header {
  padding: 0; }

.modal.full-width .modal-header {
  background: #424242;
  border-bottom: 0;
  padding: 0.625rem 1.5625rem;
  border-radius: 0rem; }
  .modal.full-width .modal-header .close,
  .modal.full-width .modal-header h4 {
    color: #fff; }
  .modal.full-width .modal-header .close {
    top: 0.4rem; }

.modal.full-width .modal-footer {
  background: #fff;
  border-top: 0; }

ul.links {
  list-style-type: none; }
  ul.links li {
    padding-bottom: 0.3125rem; }

@media screen and (min-width: 768px) {
  .modal .modal-sm {
    width: 22.1875rem; }
  .full-width {
    padding: 0 1.25rem; }
    .full-width .modal-dialog {
      max-width: 90rem;
      width: 100%; } }

.modal.fade .modal-dialog {
  -moz-animation-duration: .2s;
  -webkit-animation-duration: .2s;
  -webkit-animation-name: zoomIn;
  -webkit-transform: none;
  animation-duration: .2s;
  animation-name: zoomIn;
  transform: none; }

.modal.in .modal-dialog {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomIn {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .modal-content {
    text-align: center; }
    .modal-content .modal-header {
      border-bottom: 0 !important; }
    .modal-content .modal-footer {
      background: #fff !important;
      border-top: 0 !important; }
      .modal-content .modal-footer button:first-child {
        float: left;
        width: 45%; }
      .modal-content .modal-footer button:last-child {
        float: right;
        width: 45%; } }

.fdl-2 .nav-tabs:not(.scoped-tabs).page-tabs li a {
  font-size: 16px;
  padding: 8px 12px;
  margin-right: 12px; }
  .fdl-2 .nav-tabs:not(.scoped-tabs).page-tabs li a:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-bottom: 4px solid rgba(0, 0, 0, 0.54);
    color: #000; }
  .fdl-2 .nav-tabs:not(.scoped-tabs).page-tabs li a:focus {
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.87);
    outline: 0;
    border-bottom: 4px solid rgba(0, 0, 0, 0.54); }
  .fdl-2 .nav-tabs:not(.scoped-tabs).page-tabs li a.active {
    border-bottom: 4px solid #f60;
    font-weight: 600;
    font-size: 16px;
    color: #000;
    padding: 8px 12px;
    margin-right: 12px; }
    .fdl-2 .nav-tabs:not(.scoped-tabs).page-tabs li a.active:hover {
      border-bottom: 4px solid #f60;
      font-weight: 600;
      font-size: 16px;
      color: #000;
      padding: 8px 12px;
      margin-right: 12px;
      background-color: rgba(0, 0, 0, 0.05); }
    .fdl-2 .nav-tabs:not(.scoped-tabs).page-tabs li a.active:focus {
      border-bottom: 4px solid #f60;
      font-weight: 600;
      font-size: 16px;
      color: #000;
      padding: 8px 12px;
      margin-right: 12px;
      background-color: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.87);
      outline: 0;
      border-bottom: 4px solid #f60; }

.fdl-2 .nav-tabs:not(.scoped-tabs).app-tabs {
  border-bottom: 4px solid #f60; }
  .fdl-2 .nav-tabs:not(.scoped-tabs).app-tabs li a {
    font-size: 14px;
    font-weight: normal;
    color: #000;
    padding: 8px 36px 8px 20px;
    outline: 0px; }
    .fdl-2 .nav-tabs:not(.scoped-tabs).app-tabs li a:hover {
      background-color: rgba(0, 0, 0, 0.05); }
    .fdl-2 .nav-tabs:not(.scoped-tabs).app-tabs li a:focus {
      background-color: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.54); }
    .fdl-2 .nav-tabs:not(.scoped-tabs).app-tabs li a.active {
      font-size: 14px;
      font-weight: normal;
      color: #000;
      padding: 8px 36px 8px 20px;
      outline: 0px;
      background-color: #f60;
      border: 1px solid rgba(0, 0, 0, 0.54);
      border-bottom: 4px solid #f60; }
      .fdl-2 .nav-tabs:not(.scoped-tabs).app-tabs li a.active:hover {
        font-size: 14px;
        font-weight: normal;
        color: #000;
        padding: 8px 36px 8px 20px;
        outline: 0px;
        background-color: rgba(0, 0, 0, 0.05); }

.nav-tabs:not(.scoped-tabs) {
  border-bottom: 0.125rem solid #e0e0e0;
  line-height: 2.6rem; }
  .nav-tabs:not(.scoped-tabs) > li {
    padding: 0 1.25rem;
    position: static; }
    .nav-tabs:not(.scoped-tabs) > li:first-child {
      padding: 0; }
    .nav-tabs:not(.scoped-tabs) > li > a {
      border: 0;
      color: #212121;
      margin-right: 0;
      padding: 0.625rem 0;
      position: relative;
      text-transform: uppercase;
      border-bottom: 0.1875rem solid transparent; }
      .nav-tabs:not(.scoped-tabs) > li > a:hover {
        background-color: transparent;
        color: #69c;
        border-color: transparent;
        text-decoration: none; }
      .nav-tabs:not(.scoped-tabs) > li > a > i {
        font-size: 0.90625rem;
        left: 0;
        top: 0.875rem; }
    .nav-tabs:not(.scoped-tabs) > li i {
      font-size: 0.90625rem;
      left: 0;
      top: 0.625rem; }
    .nav-tabs:not(.scoped-tabs) > li > a.active {
      background-color: transparent;
      border: 0;
      border-bottom: 0.1875rem solid #69c;
      color: #69c;
      padding: 0.625rem 0;
      text-decoration: none; }
      .nav-tabs:not(.scoped-tabs) > li > a.active:hover, .nav-tabs:not(.scoped-tabs) > li > a.active:focus {
        background-color: transparent;
        border: 0;
        border-bottom: 0.1875rem solid #69c;
        color: #69c;
        padding: 0.625rem 0;
        text-decoration: none; }
    .nav-tabs:not(.scoped-tabs) > li.last {
      display: none; }
      .nav-tabs:not(.scoped-tabs) > li.last .dropdown {
        padding: 0.1875rem 0; }
        .nav-tabs:not(.scoped-tabs) > li.last .dropdown a.dropdown-toggle {
          background: transparent;
          color: #212121;
          padding: 0.5625rem 0;
          text-transform: uppercase; }
          .nav-tabs:not(.scoped-tabs) > li.last .dropdown a.dropdown-toggle:hover {
            color: #69c;
            text-decoration: none; }
          .nav-tabs:not(.scoped-tabs) > li.last .dropdown a.dropdown-toggle:focus, .nav-tabs:not(.scoped-tabs) > li.last .dropdown a.dropdown-toggle:active {
            background: #fff;
            border-bottom: 0.1875rem solid #69c;
            color: #69c;
            text-decoration: none; }
      .nav-tabs:not(.scoped-tabs) > li.last .dropdown-menu {
        margin-top: 0.75rem; }
        .nav-tabs:not(.scoped-tabs) > li.last .dropdown-menu a {
          background-color: #fff;
          color: #212121; }
          .nav-tabs:not(.scoped-tabs) > li.last .dropdown-menu a:hover {
            background-color: #69c;
            color: #fff; }
  .nav-tabs:not(.scoped-tabs) li > a.disabled {
    color: #777777;
    background-color: #fff; }

.scoped-tabs {
  border-bottom: 0.125rem solid #e0e0e0;
  line-height: 2.6rem;
  background-color: #f2f2f2;
  border: 0.0625rem solid #e0e0e0; }
  .scoped-tabs > li {
    padding: 0; }
    .scoped-tabs > li:first-child a.active {
      border-left: 0; }
    .scoped-tabs > li > a {
      border: 0;
      color: #212121;
      margin-right: 0;
      padding: 0 1.1875rem !important;
      position: relative;
      text-transform: uppercase;
      border-bottom: 0.1875rem solid transparent; }
      .scoped-tabs > li > a:hover {
        background-color: transparent;
        color: #69c;
        border-color: transparent;
        text-decoration: none; }
    .scoped-tabs > li .nav-link {
      border-top: 0; }
    .scoped-tabs > li:hover, .scoped-tabs > li:focus {
      background: transparent; }
    .scoped-tabs > li.active > a,
    .scoped-tabs > li.active a:hover,
    .scoped-tabs > li.active a:focus {
      background: #fff;
      border-bottom: 0.1875rem solid #fff;
      border-left: 0.0625rem solid #e0e0e0;
      border-right: 0.0625rem solid #e0e0e0;
      padding: 0.625rem 1.125rem; }
    .scoped-tabs > li.active:first-child.active > a {
      border-left: 0;
      border-right: 0.0625rem solid #e0e0e0;
      padding-left: 1.1875rem; }

.tab-content {
  padding: 0.625rem; }

.scoped-tabs + .tab-content {
  border-color: #e0e0e0;
  border-style: solid;
  border-width: 0 0.0625rem 0.0625rem; }

@media screen and (min-width: 320px) and (max-width: 992px) {
  .nav-tabs > li:nth-child(n+4) {
    display: none; }
  .nav-tabs > li.last {
    display: block; }
  .nav-tabs.stacked > li:nth-child(n+4) {
    display: block; }
  .nav-tabs.stacked > li.last {
    display: none; } }

.nav-tabs .nav-link.active {
  color: #69c; }

a:not([href]):not([tabindex]) {
  color: #69c; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: #69c;
    text-decoration: underline; }

.nav-tabs:not(.scoped-tabs) > li a > i {
  top: 0.875rem; }

.nav-tabs:not(.scoped-tabs) > li a.active > i {
  color: #69c; }

.breadcrumbs {
  list-style-type: none; }
  .breadcrumbs li {
    display: inline-block;
    margin-bottom: 1.25rem;
    padding-right: 1.0625rem;
    text-transform: uppercase; }
    .breadcrumbs li::after {
      color: #69c;
      content: '\E315';
      font-family: 'Material Icons';
      font-size: 1.25rem;
      position: absolute;
      top: -0.25rem;
      speak: none;
      left: 7.25rem; }
    .breadcrumbs li:last-child::after {
      content: ''; }

ul.pagination {
  list-style-type: none;
  margin: 1.5rem 0; }
  ul.pagination li {
    display: inline-block; }
    ul.pagination li > a {
      background: #fafafa;
      border-color: #f2f2f2;
      color: #69c;
      margin-left: -0.3125rem;
      padding: 0.5rem 0.625rem;
      position: relative; }
      ul.pagination li > a.previous, ul.pagination li > a.next {
        background: #fafafa;
        border-color: #f2f2f2; }
        ul.pagination li > a.previous i, ul.pagination li > a.next i {
          font-size: 1.125rem;
          line-height: 0.875rem;
          vertical-align: middle; }
    ul.pagination li.active a {
      background-color: #69c;
      border-color: #69c;
      color: #fff; }
    ul.pagination li.first {
      margin-right: 0.5625rem; }
      ul.pagination li.first a {
        border-radius: 0.125rem;
        padding: 0.5rem 0.9375rem 0.5rem 1.5625rem; }
        ul.pagination li.first a i {
          font-size: 1.125rem;
          left: 0.25rem;
          position: absolute;
          top: 0.4375rem; }
    ul.pagination li.last {
      margin-left: 0.5625rem; }
      ul.pagination li.last a {
        border-radius: 0.125rem;
        padding: 0.5rem 1.5625rem 0.5rem 0.9375rem; }
        ul.pagination li.last a i {
          font-size: 1.125rem;
          position: absolute;
          right: 0.25rem;
          top: 0.4375rem; }

ul.textbtn li {
  display: inline-block; }
  ul.textbtn li > a {
    background: #fff;
    border-color: #fff;
    color: #69c;
    margin-left: -0.3125rem;
    padding: 0.5rem 0.625rem;
    position: relative; }
    ul.textbtn li > a.previous, ul.textbtn li > a.next {
      background: #fafafa;
      border-color: #f2f2f2; }
      ul.textbtn li > a.previous i, ul.textbtn li > a.next i {
        font-size: 1.125rem;
        line-height: 0.875rem;
        vertical-align: middle; }
  ul.textbtn li.active a {
    background-color: #69c;
    border-color: #69c;
    color: #fff; }
  ul.textbtn li.first {
    margin-right: 0.5625rem; }
    ul.textbtn li.first a {
      border-radius: 0.125rem;
      padding: 0.5rem 0.9375rem 0.5rem 1.5625rem; }
      ul.textbtn li.first a i {
        font-size: 1.125rem;
        left: 0.25rem;
        position: absolute;
        top: 0.4375rem; }
  ul.textbtn li.last {
    margin-left: 0.5625rem; }
    ul.textbtn li.last a {
      border-radius: 0.125rem;
      padding: 0.5rem 1.5625rem 0.5rem 0.9375rem; }
      ul.textbtn li.last a i {
        font-size: 1.125rem;
        position: absolute;
        right: 0.25rem;
        top: 0.4375rem; }

ul.textbtn li > a {
  margin-left: -0.125rem; }

ul.text li {
  display: inline-block; }
  ul.text li > a {
    background: #fff;
    border-color: #fff;
    color: #69c;
    margin-left: -0.3125rem;
    padding: 0.5rem 0.625rem;
    position: relative; }
    ul.text li > a.previous, ul.text li > a.next {
      background: #fafafa;
      border-color: #f2f2f2; }
      ul.text li > a.previous i, ul.text li > a.next i {
        font-size: 1.125rem;
        line-height: 0.875rem;
        vertical-align: middle; }
  ul.text li.active a {
    background-color: #69c;
    border-color: #69c;
    color: #fff; }
  ul.text li.first {
    margin-right: 0.5625rem; }
    ul.text li.first a {
      border-radius: 0.125rem;
      padding: 0.5rem 0.9375rem 0.5rem 1.5625rem; }
      ul.text li.first a i {
        font-size: 1.125rem;
        left: 0.25rem;
        position: absolute;
        top: 0.4375rem; }
  ul.text li.last {
    margin-left: 0.5625rem; }
    ul.text li.last a {
      border-radius: 0.125rem;
      padding: 0.5rem 1.5625rem 0.5rem 0.9375rem; }
      ul.text li.last a i {
        font-size: 1.125rem;
        position: absolute;
        right: 0.25rem;
        top: 0.4375rem; }

ul.text p {
  float: left;
  line-height: 1.125rem;
  margin-bottom: 0;
  padding: 0.5rem 0.625rem; }

ul.text .current span {
  color: #212121;
  border: 0; }

ul.text .current .sr-only {
  position: absolute; }

.page-item.active .page-link {
  background-color: #69c;
  border-color: #69c; }

.pagination-lg .page-link {
  padding: 0.5rem 0.625rem;
  font-size: 1rem;
  line-height: 1.4; }

.pager {
  padding-left: 0;
  margin: 1rem 0rem;
  list-style: none;
  text-align: center; }
  .pager li {
    display: inline; }
    .pager li > a {
      display: inline-block;
      padding: 0.3125rem 0.875rem;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 0.9375rem; }
      .pager li > a:hover, .pager li > a:focus {
        text-decoration: none;
        background-color: #eeeeee; }
    .pager li a {
      text-decoration: none; }
  .pager li.disabled > a {
    color: #777777;
    background-color: #fff; }

.page-item.active > a,
.page-item.active > a:hover,
.page-item.active > a:focus,
.page-item.active > span,
.page-item.active > span:hover,
.page-item.active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7; }

.fdl-2 .step-flow li a {
  color: rgba(0, 0, 0, 0.38);
  font-weight: bold; }
  .fdl-2 .step-flow li a span.state {
    background: #757575; }
  .fdl-2 .step-flow li a span > i {
    font-weight: bold; }

.fdl-2 .step-flow li:before {
  background: rgba(0, 0, 0, 0.38); }

.fdl-2 .step-flow li.complete a {
  color: rgba(0, 0, 0, 0.38);
  font-weight: bold; }
  .fdl-2 .step-flow li.complete a span.state {
    background: #757575; }
  .fdl-2 .step-flow li.complete a span > i {
    font-weight: bold; }

.fdl-2 .step-flow li.active a {
  color: #000;
  font-weight: bold; }
  .fdl-2 .step-flow li.active a span.state {
    background-color: #f60; }
  .fdl-2 .step-flow li.active a i {
    font-weight: bold; }

.fdl-2 .step-flow li.inactive:hover a {
  color: #212121; }

.fdl-2 .step-flow li.inactive:hover span.state {
  background-color: #f60; }

ol.step-flow {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0.625rem;
  padding: 1.25rem 0; }
  ol.step-flow > li {
    display: table-cell;
    padding: 1.25rem 0;
    position: relative;
    text-align: center;
    width: 11.25rem; }
    ol.step-flow > li a {
      color: #212121; }
    ol.step-flow > li span.state {
      background: #757575;
      border-radius: 50%;
      color: #fff;
      content: ' ';
      font-size: 0.90625rem;
      left: 50%;
      margin-left: -0.9375rem;
      padding: 0.4375rem 0.6875rem;
      position: absolute;
      text-align: center;
      top: -1.125rem;
      z-index: 2; }
      ol.step-flow > li span.state + span {
        line-height: 1.25rem; }
    ol.step-flow > li::before {
      background: #757575;
      content: '';
      display: table;
      height: 0.25rem;
      left: 50%;
      position: absolute;
      top: -0.25rem;
      width: 100%; }
    ol.step-flow > li:last-child::before {
      width: 0; }
    ol.step-flow > li .split-button {
      color: #9e9e9e; }
      ol.step-flow > li .split-button .open .btn-secondary {
        background-color: transparent; }
      ol.step-flow > li .split-button:hover {
        color: #69c;
        cursor: pointer; }
      ol.step-flow > li .split-button .btn-secondary {
        border: 0;
        color: #9e9e9e;
        background-color: transparent; }
        ol.step-flow > li .split-button .btn-secondary i {
          border: 0.0625rem solid #9e9e9e;
          border-radius: 0.125rem;
          padding: 0.125rem; }
    ol.step-flow > li.inactive a {
      color: #9e9e9e; }
    ol.step-flow > li.inactive span.state {
      background: #e0e0e0; }
    ol.step-flow > li.inactive::before {
      background: #e0e0e0; }
    ol.step-flow > li.inactive:hover a {
      color: #69c;
      cursor: pointer; }
    ol.step-flow > li.inactive:hover span.state {
      background: #69c; }
    ol.step-flow > li.active a {
      color: #69c; }
    ol.step-flow > li.active span.state {
      background: #69c; }
    ol.step-flow > li.active::before {
      background: #e0e0e0; }
    ol.step-flow > li.complete a {
      color: #212121; }
    ol.step-flow > li.complete span.state {
      padding: 0.375rem 0.4375rem; }
      ol.step-flow > li.complete span.state i {
        font-size: 1rem;
        line-height: 0.875rem; }
    ol.step-flow > li.sub-steps ul {
      list-style-type: none; }
      ol.step-flow > li.sub-steps ul li:nth-child(1).active a.step-progress {
        background: #69c; }
      ol.step-flow > li.sub-steps ul li:nth-child(1).active::before {
        background: #69c;
        content: ' ';
        height: 0.25rem;
        left: 50%;
        margin-left: -0.625rem;
        position: absolute;
        top: -0.25rem;
        width: 45%; }
      ol.step-flow > li.sub-steps ul li:nth-child(1)::before {
        background: #e0e0e0; }
      ol.step-flow > li.sub-steps ul li:nth-child(1) a.step-progress {
        background: #e0e0e0;
        border-radius: 50%;
        height: 0.9375rem;
        left: 77%;
        position: absolute;
        top: -0.625rem;
        width: 0.9375rem; }
      ol.step-flow > li.sub-steps ul li:nth-child(2).active a.step-progress {
        background: #69c; }
      ol.step-flow > li.sub-steps ul li:nth-child(2).active::before {
        background: #69c;
        content: ' ';
        height: 0.25rem;
        left: 50%;
        margin-left: -0.625rem;
        position: absolute;
        top: -0.25rem;
        width: 60%; }
      ol.step-flow > li.sub-steps ul li:nth-child(2)::before {
        background: #e0e0e0; }
      ol.step-flow > li.sub-steps ul li:nth-child(2) a.step-progress {
        background: #e0e0e0;
        border-radius: 50%;
        height: 0.9375rem;
        left: 97%;
        position: absolute;
        top: -0.625rem;
        width: 0.9375rem; }
      ol.step-flow > li.sub-steps ul li:nth-child(3).active a.step-progress {
        background: #69c; }
      ol.step-flow > li.sub-steps ul li:nth-child(3).active::before {
        background: #69c;
        content: ' ';
        height: 0.25rem;
        left: 50%;
        margin-left: -0.625rem;
        position: absolute;
        top: -0.25rem;
        width: 75%; }
      ol.step-flow > li.sub-steps ul li:nth-child(3)::before {
        background: #e0e0e0; }
      ol.step-flow > li.sub-steps ul li:nth-child(3) a.step-progress {
        background: #e0e0e0;
        border-radius: 50%;
        height: 0.9375rem;
        left: 117%;
        position: absolute;
        top: -0.625rem;
        width: 0.9375rem; }

.accordion .panel {
  padding: 0;
  margin-bottom: 0rem; }
  .accordion .panel .panel-heading {
    color: #212121;
    padding: 0.8125rem 4%; }
    .accordion .panel .panel-heading h4 {
      margin-bottom: 0; }
      .accordion .panel .panel-heading h4 .panel-title {
        font-size: 3rem;
        font-weight: normal;
        position: relative; }
      .accordion .panel .panel-heading h4 a {
        display: block;
        color: inherit; }
        .accordion .panel .panel-heading h4 a:hover, .accordion .panel .panel-heading h4 a:focus {
          color: #212121;
          text-decoration: none; }
      .accordion .panel .panel-heading h4 i {
        float: right; }
    .accordion .panel .panel-heading + .panel-collapse > .panel-body {
      border-top: 0;
      padding: 1.25rem; }
      .accordion .panel .panel-heading + .panel-collapse > .panel-body a {
        font-weight: normal; }

.accordion .panel-group .panel {
  border: 0;
  box-shadow: none; }
  .accordion .panel-group .panel > .panel-heading {
    background-color: #f2f2f2; }
  .accordion .panel-group .panel.complete .panel-heading {
    background-color: #757575; }
    .accordion .panel-group .panel.complete .panel-heading a {
      color: #fff; }
  .accordion .panel-group .panel.inprogress .panel-heading {
    background-color: #69c; }
    .accordion .panel-group .panel.inprogress .panel-heading a {
      color: #fff; }
  .accordion .panel-group .panel.inprogress .progress-container {
    display: none; }
  .accordion .panel-group .panel + .panel {
    margin-top: 0.0625rem; }

.progress-container {
  float: left;
  margin-bottom: 1.25rem;
  width: 100%; }

.progress {
  background: #e0e0e0;
  border-radius: 0.5rem;
  box-shadow: none;
  height: 0.5rem;
  margin-bottom: 0.625rem; }
  .progress .progress-bar {
    background: #69c;
    box-shadow: none;
    transition: width 1.5s ease;
    width: 0; }
  .progress + .navigation {
    float: left;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
    .progress + .navigation a {
      position: absolute;
      top: 0; }
      .progress + .navigation a.prev {
        left: 0; }
      .progress + .navigation a.next {
        right: 0; }

@media screen and (min-width: 31.25rem) and (max-width: 767px) {
  ol.step-flow > li {
    display: block;
    height: 8.4375rem;
    width: auto; }
    ol.step-flow > li::before {
      height: 3.75rem;
      margin-left: -0.125rem;
      top: 2.8125rem;
      width: 0.25rem; }
    ol.step-flow > li.sub-steps {
      height: 12.5rem; }
      ol.step-flow > li.sub-steps::before {
        height: 7.8125rem;
        top: 2.8125rem;
        width: 0.25rem; }
      ol.step-flow > li.sub-steps ul {
        list-style-type: none; }
        ol.step-flow > li.sub-steps ul li:nth-child(1).active::before {
          height: 1.25rem;
          left: 50%;
          margin-left: -0.125rem;
          top: 2.8125rem;
          width: 0.25rem; }
        ol.step-flow > li.sub-steps ul li:nth-child(1)::before {
          background: #e0e0e0; }
        ol.step-flow > li.sub-steps ul li:nth-child(1) a.step-progress {
          left: 50%;
          margin-left: -0.4375rem;
          top: 30%; }
        ol.step-flow > li.sub-steps ul li:nth-child(2).active::before {
          height: 41.25rem;
          left: 50%;
          margin-left: -0.125rem;
          top: 2.8125rem;
          width: 0.25rem; }
        ol.step-flow > li.sub-steps ul li:nth-child(2)::before {
          background: #e0e0e0; }
        ol.step-flow > li.sub-steps ul li:nth-child(2) a.step-progress {
          left: 50%;
          margin-left: -0.4375rem;
          top: 50%; }
        ol.step-flow > li.sub-steps ul li:nth-child(3).active::before {
          height: 81.25rem;
          left: 50%;
          margin-left: -0.125rem;
          top: 2.8125rem;
          width: 0.25rem; }
        ol.step-flow > li.sub-steps ul li:nth-child(3)::before {
          background: #e0e0e0; }
        ol.step-flow > li.sub-steps ul li:nth-child(3) a.step-progress {
          left: 50%;
          margin-left: -0.4375rem;
          top: 70%; }
  .panel.inprogress ol.step-flow {
    display: none; }
  .panel .progress-container {
    display: block !important; } }

table {
  background-color: #fff; }

caption,
.caption {
  color: #212121;
  font-size: 1.125rem;
  font-weight: 600;
  padding-bottom: 1.437rem;
  text-align: left;
  caption-side: top; }

th {
  text-align: left; }

.table {
  margin-bottom: 1rem;
  max-width: 100%;
  width: 100%; }
  .table > thead > tr > th,
  .table > thead > td,
  .table > tbody > tr > th,
  .table > tbody > td,
  .table > tfoot > tr > th,
  .table > tfoot > td {
    line-height: 1.4;
    padding: 0.718rem 0.562rem;
    vertical-align: top; }
  .table > thead > tr > th {
    border-bottom: 0.125rem solid #e0e0e0;
    color: #757575;
    font-size: 0.875rem;
    font-weight: normal;
    padding: 0.687rem 0.562rem;
    vertical-align: bottom; }
  .table > tbody > tr.tr-has-error > td {
    border-bottom: 0.0625rem solid #dd3435; }
    .table > tbody > tr.tr-has-error > td:first-child {
      border-left: 0.0625rem solid #dd3435; }
    .table > tbody > tr.tr-has-error > td:last-child {
      border-right: 0.0625rem solid #dd3435; }
  .table > tbody > tr.tr-error-msg > td {
    border-top: 0.0625rem solid #dd3435;
    color: #dd3435;
    padding-bottom: 0.4375rem;
    padding-top: 0.3125rem; }
    .table > tbody > tr.tr-error-msg > td:first-child {
      border-left: 0.0625rem solid #dd3435; }
    .table > tbody > tr.tr-error-msg > td:last-child {
      border-right: 0.0625rem solid #dd3435; }
    .table > tbody > tr.tr-error-msg > td .material-icons {
      font-size: 1rem;
      vertical-align: bottom; }
  .table > tbody > tr:last-child {
    border-bottom: 0.0625rem solid #e0e0e0; }
  .table > tbody > .tr-group > td {
    background-color: #f2f2f2;
    font-weight: 600; }
    .table > tbody > .tr-group > td .material-icons {
      color: #757575;
      font-size: 1.25rem; }
  .table > tbody + tbody {
    border-top: 0.125rem solid #e0e0e0; }
  .table .table {
    background-color: #fff; }

.msort14 {
  font-size: 0.875rem;
  vertical-align: bottom; }

.tcell-line-two {
  padding-bottom: 0.1875rem !important; }
  .tcell-line-two > small {
    display: block;
    line-height: 1.2; }

th > .checkbox {
  margin: 0; }

td > .checkbox {
  margin: 0; }

.table-selected,
.table-selected > th,
.table-selected > td {
  background-color: #ecf2f8; }

.table-hover .table-selected:hover {
  background-color: #d9e5f1; }
  .table-hover .table-selected:hover > td,
  .table-hover .table-selected:hover > th {
    background-color: #d9e5f1; }

.expanded-row-content {
  border-left: 0.3125rem solid #9e9e9e;
  vertical-align: middle; }

@media only screen and (max-device-width: 375px) and (orientation: portrait) {
  .table-stacked table,
  .table-stacked thead,
  .table-stacked tbody,
  .table-stacked th,
  .table-stacked td,
  .table-stacked tr {
    display: block; }
  .table-stacked thead > tr {
    display: none; }
  .table-stacked table > tbody > tr > td {
    padding: 0.9375rem 0.625rem 0.9375rem 50%; }
  .table-stacked table caption {
    display: inline-block; }
  .table-stacked tr {
    border-collapse: collapse;
    border-top: 0.125rem solid #e0e0e0; }
    .table-stacked tr td {
      border: 0;
      overflow: hidden;
      position: relative;
      text-align: right;
      text-overflow: ellipsis;
      white-space: normal; }
  .table-stacked td::before {
    color: #757575;
    content: attr(data-label);
    left: 0.625rem;
    padding-right: 0.625rem;
    position: absolute;
    text-align: left;
    white-space: nowrap;
    width: 45%; }
  .responsive-adjust-6 {
    display: inline-block;
    padding-top: 0.625rem;
    width: 50%; }
    .responsive-adjust-6 > .btn {
      float: right; }
  .responsive-adjust-12 {
    display: inline-block;
    padding-top: 0.625rem;
    width: 100%; } }

.table-active, .table-active > th, .table-active > td {
  background-color: #ecf2f8; }

@media (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 12px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #e0e0e0; } }

.table > thead > tr:first-child > th {
  border-top: 0; }

.cards .card-info h5 i, .cards-2 .card-info h5 i, .cards-3 .card-info h5 i, .cards-4 .link-with-icon i {
  position: absolute;
  right: 0;
  top: 0; }

.cards, .cards-2, .cards-3, .cards-4, .cards-with-image, .media {
  border: 0.0625rem solid #e0e0e0;
  border-radius: 0.125rem;
  margin-bottom: 1.25rem; }

.cards {
  background: #f2f2f2;
  padding: 1.5rem; }
  .cards h4 {
    font-weight: normal;
    margin: 0;
    padding: 0  0 1.5625rem 1.5625rem;
    position: relative; }
    .cards h4 i:first-child {
      left: 0;
      position: absolute;
      top: 0; }
    .cards h4 i:last-child {
      left: 0px;
      position: absolute;
      right: 0;
      top: 0; }
    .cards h4 .subhead {
      color: #757575;
      display: block;
      font-size: 1rem;
      padding-top: 0.3125rem; }
  .cards .btn-group i {
    color: #fff; }
  .cards .card-info {
    display: table;
    width: 100%; }
    .cards .card-info h5 {
      color: #69c;
      margin: 0 0 0.9375rem;
      padding-right: 1.25rem;
      position: relative; }
    .cards .card-info dl {
      float: left;
      width: 100%; }
      .cards .card-info dl dt {
        color: #757575;
        float: left;
        font-weight: normal;
        padding-bottom: 0.3125rem;
        width: 45%;
        word-wrap: break-word; }
      .cards .card-info dl dd {
        float: left;
        padding-bottom: 0.3125rem;
        width: 55%;
        word-wrap: break-word; }
  .cards i {
    color: #757575;
    speak: none; }

.cards-2 {
  background: #fff;
  padding: 1.5rem; }
  .cards-2 h4 {
    font-weight: normal;
    margin: 0;
    padding: 0  0 1.5625rem 1.5625rem;
    position: relative; }
    .cards-2 h4 i:first-child {
      left: 0;
      position: absolute;
      top: 0; }
    .cards-2 h4 i:last-child {
      left: 0px;
      position: absolute;
      right: 0;
      top: 0; }
    .cards-2 h4 .subhead {
      color: #757575;
      display: block;
      font-size: 1rem;
      padding-top: 0.3125rem; }
  .cards-2 .btn-group i {
    color: #fff; }
  .cards-2 .card-info {
    display: table;
    width: 100%; }
    .cards-2 .card-info h5 {
      color: #69c;
      margin: 0 0 0.9375rem;
      padding-right: 1.25rem;
      position: relative; }
    .cards-2 .card-info dl {
      float: left;
      width: 100%; }
      .cards-2 .card-info dl dt {
        color: #757575;
        float: left;
        font-weight: normal;
        padding-bottom: 0.3125rem;
        width: 45%;
        word-wrap: break-word; }
      .cards-2 .card-info dl dd {
        float: left;
        padding-bottom: 0.3125rem;
        width: 55%;
        word-wrap: break-word; }
  .cards-2 i {
    color: #757575;
    speak: none; }
  .cards-2 h4 {
    padding-left: 0; }
  .cards-2 i.font-color-secondary {
    color: #69c; }

.cards-3 {
  background: #fafafa;
  padding: 1.5rem; }
  .cards-3 h4 {
    font-weight: normal;
    margin: 0;
    padding: 0  0 1.5625rem 1.5625rem;
    position: relative; }
    .cards-3 h4 i:first-child {
      left: 0;
      position: absolute;
      top: 0; }
    .cards-3 h4 i:last-child {
      left: 0px;
      position: absolute;
      right: 0;
      top: 0; }
    .cards-3 h4 .subhead {
      color: #757575;
      display: block;
      font-size: 1rem;
      padding-top: 0.3125rem; }
  .cards-3 .btn-group i {
    color: #fff; }
  .cards-3 .card-info {
    display: table;
    width: 100%; }
    .cards-3 .card-info h5 {
      color: #69c;
      margin: 0 0 0.9375rem;
      padding-right: 1.25rem;
      position: relative; }
    .cards-3 .card-info dl {
      float: left;
      width: 100%; }
      .cards-3 .card-info dl dt {
        color: #757575;
        float: left;
        font-weight: normal;
        padding-bottom: 0.3125rem;
        width: 45%;
        word-wrap: break-word; }
      .cards-3 .card-info dl dd {
        float: left;
        padding-bottom: 0.3125rem;
        width: 55%;
        word-wrap: break-word; }
  .cards-3 i {
    color: #757575;
    speak: none; }
  .cards-3 h4 {
    padding-left: 0; }
    .cards-3 h4 i:first-child {
      left: auto;
      right: 0; }

.cards-4 {
  padding: 0.9375rem 0.9375rem 0; }
  .cards-4 h5 {
    color: #757575;
    font-size: 0.75rem;
    padding-bottom: 0.625rem; }
  .cards-4 .link-with-icon {
    border-top: 0.0625rem solid #e0e0e0;
    color: #212121;
    display: block;
    font-size: 0.75rem;
    margin: 0 -0.9375rem;
    padding: 0.9375rem;
    position: relative; }
    .cards-4 .link-with-icon i {
      color: #757575;
      right: 0.9375rem;
      top: 0.75rem;
      speak: none; }

.cards-with-image a.see-more-link i {
  speak: none; }

.cards-with-image .image {
  background: #e0e0e0;
  height: 12.125rem;
  padding: 0.8125rem 0.9375rem;
  width: 100%; }
  .cards-with-image .image h3 {
    color: #fff;
    margin: 0; }

.cards-with-image .content {
  padding: 0.8125rem 0.9375rem; }

.media {
  padding: 0.8125rem 0.9375rem; }
  .media .img-horizontal {
    background: #e0e0e0;
    height: 10rem;
    width: 10rem; }
  .media .img-vertical {
    background: #e0e0e0;
    height: 5rem;
    width: 5rem; }

.enableFocus {
  display: block !important;
  opacity: 0;
  height: 0px;
  width: 0px;
  margin: 0px; }

.card-body-sm {
  padding: 0.5rem; }

.card-body-lg {
  padding: 1.5rem; }

.cards blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15); }

.cards .btn-primary i {
  color: #fff; }

.cards .page-item .page-link i {
  color: #69c; }

.cards .alert-success i {
  color: #54b34a; }

ul {
  list-style-type: disc;
  padding-left: 0; }
  .basic-list {
    list-style-type: none; }
    .basic-list li {
      padding-bottom: 0.3125rem; }
  .bulleted-list {
    list-style-type: disc;
    padding-left: 1.25rem; }
  .horizontal-list {
    float: left;
    list-style-type: disc;
    padding-left: 1.25rem;
    width: 100%; }
    .horizontal-list li {
      float: left;
      padding-right: 1.875rem; }
  .action-list {
    list-style-type: none; }
    .action-list li {
      border-top: 0.0625rem solid #e0e0e0;
      color: #212121;
      margin: 0;
      padding: 0.35rem 0; }
      .action-list li:last-child {
        border-bottom: 0.0625rem solid #e0e0e0; }
      .action-list li:hover {
        background: #fafafa; }
      .action-list li label {
        color: #212121; }
    .action-list li .checkbox + .checkbox {
      margin-top: 0; }
  .tree {
    list-style-type: none;
    padding-left: 0; }
    .tree li {
      padding-bottom: 0;
      position: relative; }
      .tree li::before {
        color: #757575;
        content: '\E315';
        font-family: 'Material Icons';
        font-size: 1.25rem;
        left: 0;
        position: absolute;
        top: 0.7rem; }
      .tree li a {
        color: #212121;
        display: table;
        padding: 0.9375rem 0 0.9375rem 1.5625rem;
        width: 100%; }
        .tree li a:hover {
          background: #fafafa;
          text-decoration: none; }
        .tree li a:focus, .tree li a:active {
          text-decoration: none; }
    .tree > li ul {
      display: none;
      list-style-type: none; }
    .tree li.expanded::before {
      content: '\E313'; }
    .tree li.expanded ul {
      list-style-type: none;
      padding-left: 1.25rem; }
      .tree li.expanded ul li ul li::before {
        content: ''; }

.list-group-item {
  color: #555555; }
  .list-group-item:hover {
    background-color: #f5f5f5;
    text-decoration: none;
    color: #555555; }
  .list-group-item.active {
    background-color: #69c;
    border-color: #69c;
    color: #fff; }

.badge {
  font-size: 82%;
  padding: 0.2rem 0.6rem 0.3rem;
  border-radius: 0.25rem;
  font-weight: bold; }

.badge-pill {
  border-radius: 0.625rem; }

.panel {
  border: 0.0625rem solid #e0e0e0;
  border-radius: 0.125rem;
  margin-bottom: 1.25rem;
  padding: 1.5rem; }
  .panel .panel-heading {
    background-color: transparent;
    border: 0;
    color: #212121;
    padding: 0; }
    .panel .panel-heading h4 {
      font-weight: normal;
      margin: 0 0 1.25rem; }
  .panel .panel-body {
    padding: 0; }
    .panel .panel-body p {
      margin-bottom: 1.25rem; }
    .panel .panel-body a {
      font-weight: 600; }
      .panel .panel-body a:focus, .panel .panel-body a:active {
        text-decoration: none; }

.edit-form-panel {
  float: left;
  padding: 0.8125rem 0.9375rem;
  width: 100%; }
  .edit-form-panel .form-panel-header {
    border-bottom: 0.125rem solid #e0e0e0;
    margin-bottom: 1.25rem;
    position: relative; }
    .edit-form-panel .form-panel-header h4 {
      font-size: 1.125rem;
      font-weight: 600;
      margin: 0;
      padding: 0.625rem 0; }
    .edit-form-panel .form-panel-header i {
      color: #757575;
      font-size: 1.25rem;
      position: absolute;
      right: 0;
      top: 0.6875rem; }
  .edit-form-panel .form-group p {
    word-wrap: break-word; }
  .edit-form-panel .form-group select {
    height: 2.125rem !important; }
  .edit-form-panel:hover {
    background: #fafafa;
    cursor: pointer; }
  .edit-form-panel .form-panel-content .form-group:nth-child(2n+1) {
    padding-left: 0; }
  .edit-form-panel .form-panel-content .form-group:nth-child(2n) {
    padding-right: 0; }
  .edit-form-panel .form-panel-content .form-group input,
  .edit-form-panel .form-panel-content .form-group select {
    margin-bottom: 0.9375rem; }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .form-panel-content .form-group {
    padding: 0; } }

.thumbnail {
  background-color: #e0e0e0;
  border: 0;
  border-radius: 0.125rem;
  height: auto;
  padding: 0; }

.img-w200 {
  width: 200px; }

.img-w165 {
  width: 165px; }

.img-w80 {
  width: 80px; }

.img-w100 {
  width: 100px; }

.img-w150 {
  width: 150px; }

.img-w28 {
  width: 28px; }

.lightbox .modal-content {
  background: #f2f2f2; }
  .lightbox .modal-content .modal-header {
    border-bottom: 0; }
    .lightbox .modal-content .modal-header .modal-title {
      font-size: 18px;
      padding-left: 0; }
  .lightbox .modal-content .modal-body {
    padding-bottom: 50px;
    flex: none; }
    .lightbox .modal-content .modal-body .carousel-inner > .item {
      background: #fff;
      border: 3px solid #e0e0e0;
      padding: 20px; }
      .lightbox .modal-content .modal-body .carousel-inner > .item > img {
        max-width: 100%; }
    .lightbox .modal-content .modal-body .carousel-indicators {
      text-align: center; }
      .lightbox .modal-content .modal-body .carousel-indicators.img-indicators {
        margin-left: 0;
        margin-top: 20px;
        position: static;
        width: 100%; }
        .lightbox .modal-content .modal-body .carousel-indicators.img-indicators > li {
          border: 0;
          border-radius: 0;
          height: 86px;
          margin: 0;
          margin-right: 20px;
          text-indent: 0;
          width: 160px; }
          .lightbox .modal-content .modal-body .carousel-indicators.img-indicators > li > a {
            display: block;
            color: #424242; }
            .lightbox .modal-content .modal-body .carousel-indicators.img-indicators > li > a > img {
              margin-bottom: 5px;
              width: 100%; }
          .lightbox .modal-content .modal-body .carousel-indicators.img-indicators > li:last-child {
            margin-right: 0; }
          .lightbox .modal-content .modal-body .carousel-indicators.img-indicators > li.active {
            background: transparent;
            border: 2px solid #69c;
            height: auto;
            padding: 2px;
            width: 160px; }
            .lightbox .modal-content .modal-body .carousel-indicators.img-indicators > li.active span {
              color: #69c;
              padding-top: 5px; }
    .lightbox .modal-content .modal-body .pdf-doc {
      display: inline-block; }
      .lightbox .modal-content .modal-body .pdf-doc > img {
        background: #f2f2f2;
        border: 3px solid #e0e0e0;
        margin: 0 auto;
        padding: 20px 40px;
        width: 400px; }
      .lightbox .modal-content .modal-body .pdf-doc a {
        display: block;
        text-align: right; }
        .lightbox .modal-content .modal-body .pdf-doc a i, .lightbox .modal-content .modal-body .pdf-doc a span {
          position: relative;
          top: 7px; }
    .lightbox .modal-content .modal-body .zoombtn {
      display: inline-block;
      padding-left: 10px;
      vertical-align: top; }
      .lightbox .modal-content .modal-body .zoombtn button {
        display: block;
        margin-bottom: 10px;
        padding: 5px; }
        .lightbox .modal-content .modal-body .zoombtn button i {
          font-size: 0.90625rem0.5; }

@media screen and (min-width: 320px) and (max-width: 767px) {
  .modal-body .zoombtn button {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 10px; } }

.main {
  background: #fff;
  height: 100%;
  padding: 1.25rem 0.9375rem;
  transition: margin-left .5s; }

.left-nav-icon {
  color: #f60;
  cursor: pointer; }

.left-nav-fixed {
  margin-left: 0; }

.left-nav {
  background-color: #fafafa;
  height: 100%;
  left: 0;
  margin-left: -10.625rem;
  overflow-x: hidden;
  padding-top: 1.25rem;
  position: fixed;
  top: 0;
  transition: margin-left .5s;
  width: 10.625rem;
  z-index: 3; }
  .left-nav.fixed {
    margin-left: 0; }
  .left-nav .nav-head {
    padding: 0 0.9375rem;
    position: relative; }
    .left-nav .nav-head i {
      color: #757575;
      position: absolute;
      right: 0.9375rem;
      top: 0; }
  .left-nav ul {
    padding-top: 1.25rem; }
    .left-nav ul li {
      list-style: none;
      position: relative; }
      .left-nav ul li a {
        background-color: transparent;
        color: #212121;
        display: table;
        min-height: 3.125rem;
        outline: none;
        padding: 1.0625rem 0.9375rem 1.0625rem 3.125rem;
        width: 100%; }
        .left-nav ul li a > i {
          font-size: 1.25rem;
          left: 0.9375rem;
          position: absolute;
          top: 0.875rem; }
        .left-nav ul li a:hover {
          background-color: #f2f2f2;
          color: #212121;
          text-decoration: none; }
          .left-nav ul li a:hover > i {
            color: #212121; }
        .left-nav ul li a.active {
          background-color: #69c;
          color: #fff;
          text-decoration: none; }
          .left-nav ul li a.active > i {
            color: #fff; }
      .left-nav ul li ul {
        margin: 0;
        padding: 0; }
        .left-nav ul li ul > li a {
          font-size: 0.75rem;
          padding: 0.625rem 0.1875rem 0.625rem 3.125rem; }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main {
    margin-left: 3.75rem; }
  .left-nav {
    -webkit-transition: width .5s;
    margin-left: 0;
    transition: width .5s;
    width: 3.75rem; }
    .left-nav .nav-head {
      display: none; }
    .left-nav ul li a {
      padding: 0; }
      .left-nav ul li a > span {
        display: none; }
      .left-nav ul li a i {
        left: 1.25rem; } }

.fdl-2.global-header-type-1 .logoImage {
  content: url("../../assets/images/logo-fiserv-white.png"); }

.fdl-2.global-header-type-1 .nav-vert-divider {
  margin-left: 0.75rem;
  margin-top: 0px; }

.fdl-2.global-header-type-1 .row.fdl-2 {
  margin-top: 0;
  margin-bottom: 0; }
  .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar {
    background-color: #2e8bc9;
    box-shadow: none; }
    .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar .navbar-brand.back-button {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      padding-top: 1rem;
      margin: 0; }
      .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar .navbar-brand.back-button i.material-icons {
        color: #fff; }
  .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav {
    background-color: #2e8bc9; }
    .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link {
      font-size: 0.7rem;
      color: #fff !important;
      background-color: #2e8bc9;
      padding: 0.5rem; }
      .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link i.material-icons {
        color: #fff;
        font-size: 1rem; }
      .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link.css_icon {
        padding: 10px; }
        .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link.css_icon .maximize {
          width: 12px;
          height: 12px;
          border: 2px solid #fff; }
        .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link.css_icon .minimize {
          width: 12px;
          height: 12px;
          border-bottom: 2px solid #fff; }
      .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover, .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"] {
        color: #2e8bc9 !important;
        background-color: #fff; }
        .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover i.material-icons, .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"] i.material-icons {
          color: #2e8bc9; }
        .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover.css_icon .maximize, .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"].css_icon .maximize {
          border: 2px solid #2e8bc9; }
        .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover.css_icon .minimize, .fdl-2.global-header-type-1 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"].css_icon .minimize {
          border-bottom: 2px solid #2e8bc9; }
  .fdl-2.global-header-type-1 .row.fdl-2.row_search {
    background-color: #2e8bc9; }
    .fdl-2.global-header-type-1 .row.fdl-2.row_search .search-offset {
      left: 13px; }
    .fdl-2.global-header-type-1 .row.fdl-2.row_search .custom-search-input input {
      font-size: 0.7rem;
      height: 2rem;
      border-color: #fff;
      border-left-color: transparent;
      background-color: #fff; }
      .fdl-2.global-header-type-1 .row.fdl-2.row_search .custom-search-input input:focus {
        box-shadow: none; }
    .fdl-2.global-header-type-1 .row.fdl-2.row_search a.nav-link {
      font-size: 0.7rem;
      color: #495057 !important;
      background-color: #f2f2f2;
      text-align: center; }
      .fdl-2.global-header-type-1 .row.fdl-2.row_search a.nav-link i.material-icons {
        color: #495057;
        font-size: 1rem; }
  .fdl-2.global-header-type-1 .row.fdl-2.row_main_tabs > div {
    padding: 0; }

.fdl-2.global-header-type-1 #main_tabs.nav-tabs .nav-link {
  border: none; }

.fdl-2.global-header-type-1 #main_tabs.nav-tabs .nav-link.active,
.fdl-2.global-header-type-1 #main_tabs.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f2f2f2;
  border: none; }

.fdl-2.global-header-type-1 #main_tabs.scoped-tabs {
  border: none;
  background-color: #2e8bc9; }
  .fdl-2.global-header-type-1 #main_tabs.scoped-tabs li > a {
    color: #fff;
    text-transform: none;
    font-size: 0.75rem; }
  .fdl-2.global-header-type-1 #main_tabs.scoped-tabs li > a:hover {
    color: #495057;
    background-color: #f2f2f2;
    border: none; }

.fdl-2.global-header-type-1 #myTabContent {
  background-color: #f2f2f2; }

.fdl-2.global-header-type-1 .subtabs {
  border-bottom: .125rem solid #e0e0e0;
  background-color: #2e8bc9; }

.fdl-2.global-header-type-1 #sub_tabs_buttons {
  padding-top: 5px; }
  .fdl-2.global-header-type-1 #sub_tabs_buttons button {
    border: none;
    margin-right: 2px;
    background-color: #65a4ce;
    color: #fff; }
    .fdl-2.global-header-type-1 #sub_tabs_buttons button i {
      padding: 0 5px;
      color: #fff; }

.fdl-2.global-header-type-1 #sub_tabs.nav-tabs {
  border: none; }
  .fdl-2.global-header-type-1 #sub_tabs.nav-tabs li > a {
    text-transform: none;
    color: #fff; }
    .fdl-2.global-header-type-1 #sub_tabs.nav-tabs li > a.active {
      border-color: #fff; }

.fdl-2.global-header-type-1 .nav-tabs:not(.scoped-tabs) {
  line-height: 2.6rem; }

.fdl-2.global-header-type-1 .no-padding {
  padding-left: 0;
  padding-right: 0; }

.fdl-2.global-header-type-1 .form-inline .form-control {
  width: 94%; }

.fdl-2.global-header-type-1 .input-group-btn button {
  width: 0; }

.fdl-2.global-header-type-1 .custom-search-input {
  width: 100%; }

.fdl-2.global-header-type-1 .no-padding-left {
  padding-left: 0px; }

.fdl-2.global-header-type-1 .no-padding-right {
  padding-right: 0px; }

.fdl-2.global-header-type-2 .logoImage {
  content: url("../../assets/images/logo-fiserv-h24.png"); }

.fdl-2.global-header-type-2 .nav-vert-divider {
  margin-left: 0.75rem;
  margin-top: 0px; }

.fdl-2.global-header-type-2 .row.fdl-2 {
  margin-top: 0;
  margin-bottom: 0; }
  .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar {
    background-color: #fff;
    box-shadow: none; }
    .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar .navbar-brand.back-button {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      padding-top: 1rem;
      margin: 0; }
      .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar .navbar-brand.back-button i.material-icons {
        color: #495057; }
  .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav {
    background-color: #fff; }
    .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link {
      font-size: 0.7rem;
      color: #495057 !important;
      background-color: #fff;
      padding: 0.5rem; }
      .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link i.material-icons {
        color: #495057;
        font-size: 1rem; }
      .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link.css_icon {
        padding: 10px; }
        .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link.css_icon .maximize {
          width: 12px;
          height: 12px;
          border: 2px solid #495057; }
        .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link.css_icon .minimize {
          width: 12px;
          height: 12px;
          border-bottom: 2px solid #495057; }
      .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover, .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"] {
        color: #fff !important;
        background-color: #495057; }
        .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover i.material-icons, .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"] i.material-icons {
          color: #fff; }
        .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover.css_icon .maximize, .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"].css_icon .maximize {
          border: 2px solid #fff; }
        .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover.css_icon .minimize, .fdl-2.global-header-type-2 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"].css_icon .minimize {
          border-bottom: 2px solid #fff; }
  .fdl-2.global-header-type-2 .row.fdl-2.row_search {
    background-color: #fff; }
    .fdl-2.global-header-type-2 .row.fdl-2.row_search .search-offset {
      left: 13px; }
    .fdl-2.global-header-type-2 .row.fdl-2.row_search .custom-search-input input {
      font-size: 0.7rem;
      height: 2rem;
      border-color: #495057;
      border-left-color: transparent;
      background-color: #fff; }
      .fdl-2.global-header-type-2 .row.fdl-2.row_search .custom-search-input input:focus {
        box-shadow: none; }
    .fdl-2.global-header-type-2 .row.fdl-2.row_search a.nav-link {
      font-size: 0.7rem;
      color: #495057 !important;
      background-color: #f2f2f2;
      text-align: center; }
      .fdl-2.global-header-type-2 .row.fdl-2.row_search a.nav-link i.material-icons {
        color: #495057;
        font-size: 1rem; }
  .fdl-2.global-header-type-2 .row.fdl-2.row_main_tabs > div {
    padding: 0; }

.fdl-2.global-header-type-2 #main_tabs.nav-tabs .nav-link {
  border: none; }

.fdl-2.global-header-type-2 #main_tabs.nav-tabs .nav-link.active,
.fdl-2.global-header-type-2 #main_tabs.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f2f2f2;
  border: none; }

.fdl-2.global-header-type-2 #main_tabs.scoped-tabs {
  border: none;
  background-color: #fff; }
  .fdl-2.global-header-type-2 #main_tabs.scoped-tabs li > a {
    color: #495057;
    text-transform: none;
    font-size: 0.75rem; }
  .fdl-2.global-header-type-2 #main_tabs.scoped-tabs li > a:hover {
    color: #495057;
    background-color: #f2f2f2;
    border: none; }

.fdl-2.global-header-type-2 #myTabContent {
  background-color: #f2f2f2; }

.fdl-2.global-header-type-2 .subtabs {
  border-bottom: .125rem solid #e0e0e0;
  background-color: #2e8bc9; }

.fdl-2.global-header-type-2 #sub_tabs_buttons {
  padding-top: 5px; }
  .fdl-2.global-header-type-2 #sub_tabs_buttons button {
    border: none;
    margin-right: 2px;
    background-color: #65a4ce;
    color: #fff; }
    .fdl-2.global-header-type-2 #sub_tabs_buttons button i {
      padding: 0 5px;
      color: #fff; }

.fdl-2.global-header-type-2 #sub_tabs.nav-tabs {
  border: none; }
  .fdl-2.global-header-type-2 #sub_tabs.nav-tabs li > a {
    text-transform: none;
    color: #fff; }
    .fdl-2.global-header-type-2 #sub_tabs.nav-tabs li > a.active {
      border-color: #fff; }

.fdl-2.global-header-type-2 .nav-tabs:not(.scoped-tabs) {
  line-height: 2.6rem; }

.fdl-2.global-header-type-2 .no-padding {
  padding-left: 0;
  padding-right: 0; }

.fdl-2.global-header-type-2 .form-inline .form-control {
  width: 94%; }

.fdl-2.global-header-type-2 .input-group-btn button {
  width: 0; }

.fdl-2.global-header-type-2 .custom-search-input {
  width: 100%; }

.fdl-2.global-header-type-2 .no-padding-left {
  padding-left: 0px; }

.fdl-2.global-header-type-2 .no-padding-right {
  padding-right: 0px; }

.fdl-2.global-header-type-3 .logoImage {
  content: url("../../assets/images/logo-fiserv-h24.png"); }

.fdl-2.global-header-type-3 .nav-vert-divider {
  margin-left: 0.75rem;
  margin-top: 0px; }

.fdl-2.global-header-type-3 .row.fdl-2 {
  margin-top: 0;
  margin-bottom: 0; }
  .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar {
    background-color: #1d1d1d;
    box-shadow: none; }
    .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar .navbar-brand.back-button {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      padding-top: 1rem;
      margin: 0; }
      .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar .navbar-brand.back-button i.material-icons {
        color: #fff; }
  .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav {
    background-color: #1d1d1d; }
    .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link {
      font-size: 0.7rem;
      color: #fff !important;
      background-color: #1d1d1d;
      padding: 0.5rem; }
      .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link i.material-icons {
        color: #fff;
        font-size: 1rem; }
      .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link.css_icon {
        padding: 10px; }
        .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link.css_icon .maximize {
          width: 12px;
          height: 12px;
          border: 2px solid #fff; }
        .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link.css_icon .minimize {
          width: 12px;
          height: 12px;
          border-bottom: 2px solid #fff; }
      .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover, .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"] {
        color: #1d1d1d !important;
        background-color: #fff; }
        .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover i.material-icons, .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"] i.material-icons {
          color: #1d1d1d; }
        .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover.css_icon .maximize, .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"].css_icon .maximize {
          border: 2px solid #1d1d1d; }
        .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link:hover.css_icon .minimize, .fdl-2.global-header-type-3 .row.fdl-2.row_logo_menu .navbar-nav li a.nav-link[aria-expanded="true"].css_icon .minimize {
          border-bottom: 2px solid #1d1d1d; }
  .fdl-2.global-header-type-3 .row.fdl-2.row_search {
    background-color: #1d1d1d; }
    .fdl-2.global-header-type-3 .row.fdl-2.row_search .search-offset {
      left: 13px; }
    .fdl-2.global-header-type-3 .row.fdl-2.row_search .custom-search-input input {
      font-size: 0.7rem;
      height: 2rem;
      border-color: #fff;
      border-left-color: transparent;
      background-color: #fff; }
      .fdl-2.global-header-type-3 .row.fdl-2.row_search .custom-search-input input:focus {
        box-shadow: none; }
    .fdl-2.global-header-type-3 .row.fdl-2.row_search a.nav-link {
      font-size: 0.7rem;
      color: #495057 !important;
      background-color: #f2f2f2;
      text-align: center; }
      .fdl-2.global-header-type-3 .row.fdl-2.row_search a.nav-link i.material-icons {
        color: #495057;
        font-size: 1rem; }
  .fdl-2.global-header-type-3 .row.fdl-2.row_main_tabs > div {
    padding: 0; }

.fdl-2.global-header-type-3 #main_tabs.nav-tabs .nav-link {
  border: none; }

.fdl-2.global-header-type-3 #main_tabs.nav-tabs .nav-link.active,
.fdl-2.global-header-type-3 #main_tabs.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f2f2f2;
  border: none; }

.fdl-2.global-header-type-3 #main_tabs.scoped-tabs {
  border: none;
  background-color: #1d1d1d; }
  .fdl-2.global-header-type-3 #main_tabs.scoped-tabs li > a {
    color: #fff;
    text-transform: none;
    font-size: 0.75rem; }
  .fdl-2.global-header-type-3 #main_tabs.scoped-tabs li > a:hover {
    color: #495057;
    background-color: #f2f2f2;
    border: none; }

.fdl-2.global-header-type-3 #myTabContent {
  background-color: #f2f2f2; }

.fdl-2.global-header-type-3 .subtabs {
  border-bottom: .125rem solid #e0e0e0;
  background-color: #f2f2f2; }

.fdl-2.global-header-type-3 #sub_tabs_buttons {
  padding-top: 5px; }
  .fdl-2.global-header-type-3 #sub_tabs_buttons button {
    border: none;
    margin-right: 2px;
    background-color: #757575;
    color: #fff; }
    .fdl-2.global-header-type-3 #sub_tabs_buttons button i {
      padding: 0 5px;
      color: #fff; }

.fdl-2.global-header-type-3 #sub_tabs.nav-tabs {
  border: none; }
  .fdl-2.global-header-type-3 #sub_tabs.nav-tabs li > a {
    text-transform: none;
    color: #495057; }
    .fdl-2.global-header-type-3 #sub_tabs.nav-tabs li > a.active {
      border-color: #ff6600; }

.fdl-2.global-header-type-3 .nav-tabs:not(.scoped-tabs) {
  line-height: 2.6rem; }

.fdl-2.global-header-type-3 .no-padding {
  padding-left: 0;
  padding-right: 0; }

.fdl-2.global-header-type-3 .form-inline .form-control {
  width: 94%; }

.fdl-2.global-header-type-3 .input-group-btn button {
  width: 0; }

.fdl-2.global-header-type-3 .custom-search-input {
  width: 100%; }

.fdl-2.global-header-type-3 .no-padding-left {
  padding-left: 0px; }

.fdl-2.global-header-type-3 .no-padding-right {
  padding-right: 0px; }

.navbar-light {
  background-color: #fff;
  border-bottom: 0;
  border-top: 0.125rem solid #f60;
  box-shadow: 0 0.0625rem 0.125rem 0 #d4d7db; }
  .navbar-light .navbar-text {
    color: #212121; }
  .navbar-light .badge {
    background-color: #f60;
    font-size: 0.625rem;
    font-weight: normal;
    border-radius: .625rem;
    color: #fff; }
  .navbar-light .navbar-nav .nav-link {
    background-color: #fff;
    color: #212121; }
    .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
      background-color: #fff;
      color: #212121; }
  .navbar-light .navbar-nav .navbar-sub-menu > ul > li .nav-link {
    background-color: #f5f5f5;
    color: #212121; }
  .navbar-light .navbar-nav > li > a.dropdown-toggle:focus {
    outline: 0.3125rem auto -webkit-focus-ring-color; }
  .navbar-light .navbar-nav > li > a {
    color: #212121; }
    .navbar-light .navbar-nav > li > a:hover, .navbar-light .navbar-nav > li > a:focus {
      background-color: #fff;
      color: #212121; }
    .navbar-light .navbar-nav > li > a .material-icons {
      color: #f60;
      font-size: 1.875rem;
      padding: 0;
      vertical-align: middle;
      line-height: 0; }
    .navbar-light .navbar-nav > li > a .caret-custom {
      color: #757575;
      font-size: 0.875rem;
      speak: none; }
    .navbar-light .navbar-nav > li > a .thumbnail {
      display: inline-block;
      margin: 0;
      padding: 0;
      vertical-align: middle; }
    .navbar-light .navbar-nav > li > a.trunc-padding-t16 {
      padding-bottom: 1rem;
      padding-top: 1rem; }
  .navbar-light .navbar-nav > .active > a, .navbar-light .navbar-nav > .active > a:hover, .navbar-light .navbar-nav > .active > a:focus {
    background-color: #fff;
    color: #212121; }
  .navbar-light .navbar-nav > .disabled > a, .navbar-light .navbar-nav > .disabled > a:hover, .navbar-light .navbar-nav > .disabled > a:focus {
    background-color: #fff;
    color: #212121; }
  .navbar-light .navbar-nav > .open > a, .navbar-light .navbar-nav > .open > a:hover, .navbar-light .navbar-nav > .open > a:focus {
    background-color: #fff;
    color: #212121; }
  .navbar-light .navbar-nav .user-settings {
    width: 17.3125rem; }
  .navbar-light .navbar-nav li > .dropdown-menu {
    background-color: #f5f5f5;
    border: 0;
    margin-top: 0.0625rem; }
    .navbar-light .navbar-nav li > .dropdown-menu .user-settings-logout > img {
      padding: 0.75rem 0 1.125rem; }
    .navbar-light .navbar-nav li > .dropdown-menu .user-settings-logout > .btn-secondary {
      border-color: #f60;
      color: #f60;
      padding: 0.5rem 0.8125rem; }
    .navbar-light .navbar-nav li > .dropdown-menu .user-settings-links > li > a {
      clear: both;
      color: #212121;
      display: block;
      font-weight: normal;
      line-height: 1.2;
      padding: 0.3125rem 0;
      text-decoration: none;
      white-space: nowrap; }
      .navbar-light .navbar-nav li > .dropdown-menu .user-settings-links > li > a:first-child {
        padding-top: 0.75rem; }
  .navbar-light .navbar-nav li > .dropdown-bordered {
    border-width: 0 0.0625rem 0.0625rem 0.0625rem;
    border-color: #e0e0e0;
    border-style: solid; }
  .navbar-light .navbar-collapse {
    margin-right: 0.9375rem; }

.navbar-brand {
  float: left;
  height: 3.75rem;
  max-height: 3.75rem;
  padding: 0.75rem 0 1.188rem 1.5rem; }

.navbar-form {
  margin-bottom: 0;
  margin-top: 0;
  padding: 0.8125rem 0 0.8125rem 2.25rem; }

.nav-help-icon {
  color: #757575;
  font-size: 1.25rem;
  padding-top: 1.125rem;
  vertical-align: middle; }

.nav-vert-divider {
  border-left: 0.0625rem solid #e0e0e0;
  content: ' ';
  height: 2.188rem;
  margin-right: 0.75rem;
  margin-top: 0.75rem; }

.nav > li > a {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

@media (min-width: 1440px) {
  .navbar-brand {
    padding: 0.75rem 0 1.188rem 3rem; }
  .navbar-form {
    padding-right: 3.75rem; }
  .navbar-nav > li > a {
    padding-bottom: 1.5rem;
    padding-top: 1.25rem; }
    .navbar-nav > li > a.trunc-padding-t14 {
      padding-bottom: 1rem;
      padding-top: 0.875rem; }
  li.xs-search-visible {
    display: none; } }

@media (min-width: 1024px) {
  .navbar-default {
    box-shadow: 0 0.0625rem 0.125rem 0.0625rem #d4d7db; }
  .navbar-form .input-group > .form-control {
    width: 26.88rem; }
  .form-inline .form-control {
    width: 26.88rem; }
  .navbar-nav > li > a {
    padding-bottom: 1rem;
    padding-top: 1rem; }
    .navbar-nav > li > a.trunc-padding-t14 {
      padding-bottom: 1.25rem;
      padding-top: 1.25rem; }
  li.xs-search-visible {
    display: none; } }

@media (min-width: 481px) and (max-width: 768px) {
  .navbar-default {
    box-shadow: 0 0.0625rem 0.125rem 0.0625rem #d4d7db; }
  .navbar-form {
    padding: 0.8125rem 0 0.8125rem 1.5rem; }
  li > .dropdown-menu {
    margin-top: 0.125rem; }
  .navbar-form .input-group > .form-control {
    width: 16rem; }
  .form-inline .form-control {
    width: 16rem; }
  .navbar-nav > li > a {
    padding-bottom: 1.5rem;
    padding-top: 1.25rem; }
    .navbar-nav > li > a.trunc-padding-t14 {
      padding-bottom: 1rem;
      padding-top: 0.875rem; }
    .navbar-nav > li > a .uname {
      display: none; }
  li.xs-search-visible {
    display: none; }
    li.xs-search-visible .material-icons {
      display: none; } }

@media (min-width: 321px) and (max-width: 480px) {
  .navbar-default {
    box-shadow: 0 0.0625rem 0.125rem 0.125rem #d4d7db; }
  .navbar-form {
    padding: 0.8125rem 0 0.8125rem 1.5rem; }
  li > .dropdown-menu {
    margin-top: 0.125rem; }
  .navbar-nav > li.sm-xs-device-hide {
    display: none; }
  .navbar-form .input-group > .form-control {
    width: 9.375rem; }
  .form-inline .form-control {
    width: 9.375rem; }
  .navbar-nav > li > a {
    padding-bottom: 1.5rem;
    padding-top: 1.25rem; }
    .navbar-nav > li > a.trunc-padding-t14 {
      padding-bottom: 1rem;
      padding-top: 0.875rem; }
    .navbar-nav > li > a .uname {
      display: none; }
  li.xs-search-visible {
    display: none; }
    li.xs-search-visible .material-icons {
      display: none; } }

@media (max-width: 320px) {
  .navbar-default {
    box-shadow: 0 0.0625rem 0.125rem 0.125rem #d4d7db; }
  .navbar-form {
    display: none;
    padding: 0.8125rem 0 0.8125rem 1.5rem; }
  li > .dropdown-menu {
    margin-top: 0.125rem; }
  .navbar-nav > li.sm-xs-device-hide {
    display: none; }
  li.xs-search-visible {
    display: inline-block; }
    li.xs-search-visible .material-icons {
      color: #757575;
      font-size: 1.875rem;
      padding-top: 0.9375rem; }
  .navbar-nav > li > a {
    padding-bottom: 1.5rem;
    padding-top: 1.25rem; }
    .navbar-nav > li > a.trunc-padding-t14 {
      padding-bottom: 1rem;
      padding-top: 0.875rem; }
    .navbar-nav > li > a .uname {
      display: none; } }

.navbar-inverse {
  background-color: #f60;
  border-bottom: 0;
  border-color: transparent;
  box-shadow: 0 0.0625rem 0.125rem 0 #d4d7db;
  margin-bottom: 0; }
  .navbar-inverse .navbar-text {
    color: #212121; }
  .navbar-inverse .badge {
    background-color: #f60;
    font-size: 0.625rem;
    font-weight: normal;
    border-radius: .625rem;
    color: #fff; }
  .navbar-inverse .navbar-nav > li > a.dropdown-toggle:focus {
    outline: 0.3125rem auto -webkit-focus-ring-color; }
  .navbar-inverse .navbar-nav > li > a {
    color: #fff; }
    .navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
      background-color: #f60;
      color: #fff !important; }
    .navbar-inverse .navbar-nav > li > a .material-icons {
      color: #fff;
      font-size: 1.875rem;
      padding: 0;
      vertical-align: middle; }
    .navbar-inverse .navbar-nav > li > a .caret-custom {
      color: #fff;
      font-size: 0.875rem; }
    .navbar-inverse .navbar-nav > li > a .thumbnail {
      display: inline-block;
      margin: 0;
      padding: 0;
      vertical-align: middle; }
    .navbar-inverse .navbar-nav > li > a.trunc-padding-t16 {
      padding-bottom: 1rem;
      padding-top: 1rem; }
  .navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
    background-color: #fff;
    color: #212121; }
  .navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
    background-color: #fff;
    color: #212121; }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
    background-color: #f60;
    color: #fff; }
  .navbar-inverse .navbar-nav .user-settings {
    width: 17.3125rem; }
  .navbar-inverse .navbar-nav li > .dropdown-menu {
    background-color: #f5f5f5;
    border: 0;
    margin-top: 0.0625rem; }
    .navbar-inverse .navbar-nav li > .dropdown-menu .user-settings-logout > img {
      padding: 0.75rem 0 1.125rem; }
    .navbar-inverse .navbar-nav li > .dropdown-menu .user-settings-logout > .btn-secondary {
      border-color: #f60;
      color: #f60;
      padding: 0.5rem 0.8125rem; }
    .navbar-inverse .navbar-nav li > .dropdown-menu .user-settings-links > li > a {
      clear: both;
      color: #212121;
      display: block;
      font-weight: normal;
      line-height: 1.2;
      padding: 0.3125rem 0;
      text-decoration: none;
      white-space: nowrap; }
      .navbar-inverse .navbar-nav li > .dropdown-menu .user-settings-links > li > a:first-child {
        padding-top: 0.75rem; }
  .navbar-inverse .nav-help-icon {
    color: #ffd3b8;
    font-size: 1.25rem;
    padding-top: 1.125rem;
    vertical-align: middle; }
  .navbar-inverse .navbar-form .btn > .material-icons,
  .navbar-inverse .form-inline .btn > .material-icons {
    color: #fff; }
  .navbar-inverse .navbar-form .input-group > .form-control,
  .navbar-inverse .navbar-form .form-control,
  .navbar-inverse .form-inline .input-group > .form-control,
  .navbar-inverse .form-inline .form-control {
    background-color: #ff8b42;
    border-color: transparent;
    color: #fff; }
    .navbar-inverse .navbar-form .input-group > .form-control::-webkit-input-placeholder,
    .navbar-inverse .navbar-form .form-control::-webkit-input-placeholder,
    .navbar-inverse .form-inline .input-group > .form-control::-webkit-input-placeholder,
    .navbar-inverse .form-inline .form-control::-webkit-input-placeholder {
      color: #fff; }
    .navbar-inverse .navbar-form .input-group > .form-control::-moz-placeholder,
    .navbar-inverse .navbar-form .form-control::-moz-placeholder,
    .navbar-inverse .form-inline .input-group > .form-control::-moz-placeholder,
    .navbar-inverse .form-inline .form-control::-moz-placeholder {
      color: #fff; }
    .navbar-inverse .navbar-form .input-group > .form-control:-moz-placeholder,
    .navbar-inverse .navbar-form .form-control:-moz-placeholder,
    .navbar-inverse .form-inline .input-group > .form-control:-moz-placeholder,
    .navbar-inverse .form-inline .form-control:-moz-placeholder {
      color: #fff; }
    .navbar-inverse .navbar-form .input-group > .form-control:-ms-input-placeholder,
    .navbar-inverse .navbar-form .form-control:-ms-input-placeholder,
    .navbar-inverse .form-inline .input-group > .form-control:-ms-input-placeholder,
    .navbar-inverse .form-inline .form-control:-ms-input-placeholder {
      color: #fff; }
  @media (max-width: 320px) {
    .navbar-inverse li.xs-search-visible {
      display: inline-block; }
      .navbar-inverse li.xs-search-visible .material-icons {
        color: #fff; } }
  .navbar-inverse .navbar-collapse {
    margin-right: 0.9375rem; }

.navbar-ext .container-fluid {
  padding-left: 0;
  padding-right: 0; }

.navbar-ext .navbar-collapse {
  padding-left: 1.5rem; }

.navbar-ext .navbar-brand {
  float: left;
  padding-left: 2.438rem; }

.navbar-ext .nav-link-ext {
  float: right;
  margin-right: 0.75rem; }
  .navbar-ext .nav-link-ext .list-inline > li {
    font-size: 0.75rem;
    margin-top: 0.75rem;
    padding-right: 0.75rem; }
    .navbar-ext .nav-link-ext .list-inline > li:first-child {
      border-right: 0.0625rem solid #e0e0e0; }
    .navbar-ext .nav-link-ext .list-inline > li > a, .navbar-ext .nav-link-ext .list-inline > li > a:focus, .navbar-ext .nav-link-ext .list-inline > li > a:hover {
      color: #212121;
      text-decoration: none; }

.navbar-ext .navbar-toggler {
  padding: .85rem .75rem;
  background-color: #fff;
  border: 0;
  position: absolute;
  right: 1.5rem; }
  .navbar-ext .navbar-toggler:hover, .navbar-ext .navbar-toggler:focus {
    background-color: #fff; }
  .navbar-ext .navbar-toggler .material-icons {
    color: #f60;
    font-size: 1.875rem;
    padding-right: 0.375rem;
    vertical-align: middle; }

.navbar-ext .navbar-nav {
  clear: both; }
  .navbar-ext .navbar-nav > li > a {
    border-bottom: 0.125rem solid transparent;
    margin: 0 1.375rem 0 0.75rem;
    padding: 0;
    padding-bottom: 0.8125rem; }
    .navbar-ext .navbar-nav > li > a:hover, .navbar-ext .navbar-nav > li > a:focus {
      background-color: #fff;
      border-bottom: 0.125rem solid #f60;
      color: #212121; }
    .navbar-ext .navbar-nav > li > a:focus {
      font-weight: 600; }
  .navbar-ext .navbar-nav > li:first-child > a {
    margin-left: 0; }

@media (min-width: 768px) {
  .navbar-ext .navbar-sub-menu {
    position: static; }
    .navbar-ext .navbar-sub-menu .dropdown-menu {
      left: 0;
      position: absolute;
      right: 0; }
      .navbar-ext .navbar-sub-menu .dropdown-menu > li {
        display: inline-block; }
        .navbar-ext .navbar-sub-menu .dropdown-menu > li:first-child {
          margin-left: 0.75rem; }
      .navbar-ext .navbar-sub-menu .dropdown-menu > li > a {
        padding: 0.9375rem 1.063rem; } }

@media (max-width: 768px) and (min-width: 480px) {
  .navbar-ext .nav-link-ext {
    display: none; } }

@media screen and (max-width: 480px) {
  .navbar-ext .navbar-toggler {
    display: block;
    position: absolute;
    right: 1.5rem; }
    .navbar-ext .navbar-toggler .material-icons {
      color: #f60;
      font-size: 1.875rem;
      padding-right: 0.375rem;
      vertical-align: middle; }
  .navbar-ext .nav-link-ext {
    display: none; }
  .navbar-ext .navbar-nav {
    clear: both; }
    .navbar-ext .navbar-nav > li > a:hover, .navbar-ext .navbar-nav > li > a:focus {
      background-color: #fff;
      border-bottom: 0.125rem solid transparent;
      color: #212121; }
    .navbar-ext .navbar-nav > li > a:focus {
      font-weight: 600; }
    .navbar-ext .navbar-nav > li:first-child > a {
      margin-left: 0.75rem; } }

@media (max-width: 736px) and (min-width: 300px) {
  .navbar-internal .navbar-header {
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .navbar-internal .navbar-header .navbar-brand {
      margin-left: -0.9375rem; }
  .navbar-internal .navbar-collapse {
    border-color: transparent;
    display: block; }
    .navbar-internal .navbar-collapse .navbar-nav {
      float: right;
      margin: 0; }
      .navbar-internal .navbar-collapse .navbar-nav li {
        float: left; }
        .navbar-internal .navbar-collapse .navbar-nav li .dropdown-menu {
          position: absolute; }
    .navbar-internal .navbar-collapse .navbar-form {
      border: 0;
      float: left;
      margin-left: 0;
      padding-left: 0.625rem; } }

@media (min-width: 768px) {
  .navbar-toggler {
    display: none; } }

@media all and (min-width: 768px) {
  .navbar-nav {
    flex-direction: row; } }

@media not all and (min-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    display: none; } }

.nav-pills .nav-link .badge {
  border-radius: 0.625rem;
  padding: 0.1875rem 0.4375rem;
  font-size: 0.75rem; }

.nav-pills .nav-link .badge-light {
  color: #fff;
  background-color: #757575; }

.nav-pills .nav-link.active {
  background-color: #69c; }
  .nav-pills .nav-link.active .badge-light {
    color: #69c;
    background-color: #fff; }

.nav-pills .nav-link:hover {
  background-color: #eeeeee; }

.nav-pills .nav-item > a.dropdown-toggle .material-icons {
  font-size: 1rem;
  vertical-align: middle; }

.navbar {
  padding: 0; }

.tooltip {
  font-family: "Open Sans", Arial, Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  opacity: .1;
  font-size: 0.75rem;
  transform: translate3d(0, -10px, 0), 0, 0, 0;
  transition: opacity 300ms ease-in, 300ms cubic-bezier(0.5, 0.8, 0, 1); }
  .tooltip.in {
    opacity: 1; }
  .tooltip.top {
    margin-top: -0.187rem;
    padding: 0.625rem 0; }
  .tooltip.right {
    margin-left: 0.187rem;
    padding: 0 0.625rem; }
  .tooltip.bottom {
    margin-top: 0.187rem;
    padding: 0.625rem 0; }
  .tooltip.left {
    margin-left: -0.187rem;
    padding: 0 0.625rem; }
  .tooltip.top > .tooltip-arrow {
    border-top-color: #424242;
    border-width: 0.875rem 0.625rem 0;
    margin-left: -0.625rem; }
  .tooltip.top-left > .tooltip-arrow {
    border-top-color: #424242;
    border-width: 0.875rem 0.625rem 0;
    margin-bottom: -0.625rem; }
  .tooltip.top-right > .tooltip-arrow {
    border-top-color: #424242;
    border-width: 0.625rem 0.625rem 0;
    margin-bottom: -0.625rem; }
  .tooltip.right > .tooltip-arrow {
    border-right-color: #424242;
    border-width: 0.625rem 0.875rem 0.625rem 0;
    margin-top: -0.625rem; }
  .tooltip.left > .tooltip-arrow {
    border-left-color: #424242;
    border-width: 0.625rem 0 0.625rem 0.875rem;
    margin-top: -0.625rem; }
  .tooltip.bottom > .tooltip-arrow {
    border-bottom-color: #424242;
    border-width: 0 0.625rem 0.875rem;
    margin-left: -0.625rem; }
  .tooltip.bottom-left > .tooltip-arrow {
    border-bottom-color: #424242;
    border-width: 0 0.625rem 0.875rem;
    margin-top: -0.625rem; }
  .tooltip.bottom-right > .tooltip-arrow {
    border-bottom-color: #424242;
    border-width: 0 0.625rem 0.875rem;
    margin-top: -0.625rem; }

.tooltip-inner {
  background-color: #424242;
  border-radius: 0.125rem;
  color: #fff;
  max-width: 15.625rem;
  padding: 1.25rem 0.937rem;
  text-align: left; }

.fdl-2-tooltip ~ .tooltip[x-placement="top"] .arrow::before {
  border-width: 0 2px 2px 0;
  border-color: #2e8bc9;
  transform: rotate(45deg);
  padding: 0 0 12px 12px;
  top: -7px;
  border-top-color: unset;
  background-color: #fff; }

.fdl-2-tooltip ~ .tooltip[x-placement="right"] .arrow::before {
  border-width: 0 0 2px 2px;
  border-color: #2e8bc9;
  transform: rotate(45deg);
  padding: 0 0 12px 12px;
  right: -7px;
  border-top-color: unset;
  background-color: #fff; }

.fdl-2-tooltip ~ .tooltip[x-placement="bottom"] .arrow::before {
  border-width: 2px 0 0 2px;
  border-color: #2e8bc9;
  transform: rotate(45deg);
  padding: 0 0 12px 12px;
  bottom: -7px;
  border-bottom-color: unset;
  background-color: #fff; }

.fdl-2-tooltip ~ .tooltip[x-placement="left"] .arrow::before {
  border-width: 2px 2px 0 0;
  border-color: #2e8bc9;
  transform: rotate(45deg);
  padding: 0 0 12px 12px;
  left: -8px;
  border-left-color: unset;
  background-color: #fff; }

.fdl-2-tooltip ~ .tooltip .tooltip-inner {
  background-color: #fff;
  border-radius: 0.125rem;
  color: rgba(0, 0, 0, 0.87);
  max-width: 15.625rem;
  padding: 1.25rem 0.937rem;
  text-align: left;
  border: 2px solid #2e8bc9;
  box-shadow: 0 0.125rem 1rem 0.125rem rgba(0, 0, 0, 0.32); }

.btn.btn-indeterminate {
  height: 2.5rem;
  width: 100%; }
  .btn.btn-indeterminate .loader {
    background-size: 1.5rem 1.5rem;
    display: none;
    height: 1.5rem;
    margin: 0 auto;
    width: 1.5rem; }
  .btn.btn-indeterminate i {
    display: none;
    font-size: 1.25rem;
    margin-top: -0.187rem; }

.btn .btn-indeterminate-small {
  background-size: 1rem 1rem;
  float: right;
  height: 1rem;
  width: 1rem; }

.linear-loader {
  background: #e0e0e0;
  border-radius: 0.375rem;
  height: 0.625rem;
  width: 100%; }
  .linear-loader .indicator {
    background: #69c;
    border-radius: 0.375rem;
    height: 100%;
    transition: width 1.5s ease;
    width: 0; }

.loader {
  background-image: url("../../assets/images/cube.svg");
  background-size: 7.5rem 7.5rem;
  height: 7.5rem;
  width: 7.5rem; }

.modal .loader {
  background-size: 4rem 4rem;
  height: 4rem;
  margin-left: 50%;
  margin-top: 50%;
  width: 4rem; }

.prec {
  position: relative;
  top: 1.75rem; }

.circle {
  background-color: #fff;
  border-radius: 100%;
  height: 6.875rem;
  left: 0.625rem;
  position: relative;
  text-align: center;
  top: 0.625rem;
  width: 6.875rem; }

.active-border {
  background-color: #69c;
  background-image: linear-gradient(91deg, transparent 50%, #e0e0e0 50%), linear-gradient(90deg, #e0e0e0 50%, transparent 50%);
  border-radius: 100%;
  height: 8.125rem;
  margin-bottom: 1.25rem;
  position: relative;
  text-align: center;
  width: 8.125rem; }
  .active-border.small {
    display: inline-block;
    height: 3.125rem;
    width: 3.125rem; }
    .active-border.small .circle {
      height: 2.5rem;
      left: 0.312rem;
      top: 0.312rem;
      width: 2.5rem; }

.prec-small {
  display: inline;
  left: 0.625rem;
  position: relative;
  top: -2.5rem; }

.sample-block {
  border: 0.0625rem solid #e0e0e0;
  padding: 0.9375rem;
  position: relative; }
  .sample-block .block-overlay {
    background: #000;
    display: none;
    height: 100%;
    left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    width: 100%; }
  .sample-block .loader {
    background-size: 2rem 2rem;
    display: none;
    height: 2rem;
    left: 50%;
    margin-left: -1.375rem;
    margin-top: -1.375rem;
    position: absolute;
    top: 50%;
    width: 2rem; }

.loader-gif {
  background-image: url("../../assets/images/loader-gif.gif"); }

.fdl-2 footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem 2.625rem;
  color: #fff;
  border-top: 2px solid #ff6600;
  line-height: 2.2; }
  @media (max-width: 575.98px) {
    .fdl-2 footer {
      padding: 1rem 0.875rem; } }
  .fdl-2 footer .contacts-link ul {
    padding-right: 0.625rem; }
  @media (max-width: 575.98px) {
    .fdl-2 footer .contacts-link ul {
      padding-right: 0; } }
  .fdl-2 footer .contacts-us {
    padding-right: 0.875rem; }
    .fdl-2 footer .contacts-us strong {
      padding-right: 0.875rem; }
    @media (max-width: 575.98px) {
      .fdl-2 footer .contacts-us {
        margin-bottom: 0.625rem;
        font-size: 0.75rem; }
        .fdl-2 footer .contacts-us strong {
          padding-right: 0; } }
  .fdl-2 footer .copyright {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.7); }
    .fdl-2 footer .copyright ul,
    .fdl-2 footer .copyright p {
      font-size: 0.75rem; }
    @media (max-width: 575.98px) {
      .fdl-2 footer .copyright ul {
        margin-bottom: 0.625rem; }
        .fdl-2 footer .copyright ul li {
          margin-right: 0.3125rem; } }
  .fdl-2 footer div p,
  .fdl-2 footer div ul {
    margin-bottom: 0; }

.navbar-footer::after {
  display: block;
  clear: both;
  content: ""; }

@media (min-width: 48rem) {
  .navbar-footer {
    float: left;
    left: 0;
    position: absolute; } }

.footer-int {
  background-color: #fff;
  border-bottom: 0;
  border-top: 0.125rem solid #f60;
  bottom: 0;
  left: 0;
  max-height: 3.75rem;
  position: absolute;
  right: 0; }
  .footer-int .footer-nav {
    float: left;
    margin-left: 4rem; }
  .footer-int .list-inline {
    padding-bottom: 1.25rem;
    padding-top: 1.3125rem; }
    .footer-int .list-inline > li {
      padding-right: 0.625rem; }
    .footer-int .list-inline > li > a {
      color: #757575; }
      .footer-int .list-inline > li > a:hover, .footer-int .list-inline > li > a:focus {
        color: #757575;
        text-decoration: none; }
      .footer-int .list-inline > li > a .material-icons {
        color: #757575;
        font-size: 1rem;
        padding: 0;
        padding-right: 0.5rem;
        vertical-align: middle;
        speak: none; }
  .footer-int .navbar-brand {
    float: left;
    height: 3.75rem;
    max-height: 3.75rem;
    padding: 1rem 0 1.125rem 1.5rem; }
  .footer-int .footer-btn {
    margin-right: -0.9375rem;
    padding: 0.8125rem 1.5rem; }
    .footer-int .footer-btn .btn {
      margin-left: 0.75rem; }
  @media (max-width: 480px) {
    .footer-int .list-inline .material-icons {
      display: none; }
    .footer-int .footer-nav {
      margin-left: 1.5rem; } }
  @media (min-width: 321px) and (max-width: 414px) {
    .footer-int .footer-btn {
      display: none; } }
  @media (max-width: 320px) {
    .footer-int .footer-btn {
      display: none; } }

.footer-ext {
  background-color: #fff;
  border-top: 0.125rem solid #f60;
  bottom: 0;
  height: auto;
  left: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 2.25rem;
  position: fixed;
  right: 0;
  width: 100%; }
  .footer-ext .footer-ext-list {
    float: left;
    padding-bottom: 0.75rem;
    padding-right: 3.375rem; }
    .footer-ext .footer-ext-list > li > a {
      color: #757575; }
    .footer-ext .footer-ext-list > li > a:hover, .footer-ext .footer-ext-list > li > a:focus {
      color: #757575;
      text-decoration: none; }
    .footer-ext .footer-ext-list > li {
      padding-bottom: 0.5rem; }
      .footer-ext .footer-ext-list > li:first-child {
        color: #212121;
        font-size: 1.125rem;
        padding-bottom: 0.25rem; }
  .footer-ext .footer-ext-inline {
    border-top: 0.0625rem solid #e0e0e0;
    clear: both;
    margin-left: 0.9375rem; }
  .footer-ext .footer-nav {
    float: left;
    margin-left: 4rem; }
  .footer-ext .navbar-brand {
    float: left;
    margin-left: -0.9375rem;
    padding: 1rem 0 1.125rem; }
  .footer-ext .list-inline {
    padding-bottom: 1.25rem;
    padding-top: 1.3125rem; }
    .footer-ext .list-inline > li {
      padding-right: 1.625rem; }
    .footer-ext .list-inline > li > a {
      color: #757575; }
      .footer-ext .list-inline > li > a:hover, .footer-ext .list-inline > li > a:focus {
        color: #757575;
        text-decoration: none; }
      .footer-ext .list-inline > li > a .material-icons {
        color: #757575;
        font-size: 1rem;
        padding: 0;
        padding-right: 0.5rem;
        vertical-align: middle; }
  .footer-ext .align-ad {
    text-align: right; }
  @media (max-width: 480px) {
    .footer-ext .align-ad {
      padding-bottom: 1.5rem;
      text-align: left; }
    .footer-ext .footer-nav {
      float: left;
      margin-left: 2.25rem; }
    .footer-ext .list-inline > li {
      padding-right: 1rem; } }
  @media (min-width: 321px) and (max-width: 480px) {
    .footer-ext .align-ad {
      padding-bottom: 1.5rem;
      text-align: left; }
    .footer-ext .footer-nav {
      clear: both;
      float: left;
      margin-left: -0.9375rem;
      padding-top: 0; }
      .footer-ext .footer-nav .list-inline {
        padding-top: 0; }
        .footer-ext .footer-nav .list-inline > li {
          padding-right: 1rem; }
    .footer-ext .navbar-brand {
      height: 3.4375rem;
      padding: 1rem 0 0; }
    .footer-ext .footer-ext {
      padding-left: 0; } }
  @media (max-width: 320px) {
    .footer-ext .align-ad {
      text-align: left; }
    .footer-ext .footer-nav {
      clear: both;
      float: left;
      margin-left: -0.9375rem;
      padding-top: 0; }
      .footer-ext .footer-nav .list-inline {
        padding-top: 0; }
        .footer-ext .footer-nav .list-inline > li {
          padding-right: 0.75rem; }
    .footer-ext .navbar-brand {
      height: 3.4375rem;
      padding: 1rem 0 0; } }

@media (min-width: 0) and (max-width: 480px) {
  .footer-ext {
    margin-left: -0.625rem;
    padding-left: 0; } }

/*! Fiserv fdl-bootstrap-4 - v4.0.8 - 2018-11-23 */.fdl-bootstrap-version:before { content: "4.0.8" } .fdl-bootstrap-build-date:before { content: "Fri Nov 23 2018 11:44:54" } 